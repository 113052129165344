import React, { useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Typography, Form, Input, Button, Modal, Carousel, Checkbox } from 'antd';
// import { Button } from "@blueprintjs/core";
import { UserOutlined } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loginState, userState } from '../store/user-store';

import { apiLogin } from '../common-lib/custom-axios';

const { Title } = Typography;

function Login() {

    let navigate = useNavigate();

    const userInput = useRef();

    // 시작할때 포커스를 해당 input 폼에서 시작하도록 함
    useLayoutEffect(() => {
        if(userInput.current !== null) userInput.current.focus();
    })

    const [{ isLogin, isAdmin, isSession }, setLoginState] = useRecoilState(loginState);
    const [{ userId,
             userName,
             admin,                   // 관리자, 비관리자 (관리자 사이트 접속유무 등)
             userGroupId,             // 그룹 아이디 넘버
             department,              // 소속부서
             postPosition,            // 직위
             onesDuty,                // 직책
             territory,               // 담당구역
             birthday,                // 생일
             employeeDate,            // 입사일
             mobile,                  // 핸드폰
             gender,                  // 남성, 여성
             shippingRight,           // 배송을 할 수 있는 담당자 유무
             stasmp,                  // 도장 이미지명                       // 두개는 없애도 될듯함. 특정 폴더에 아이디 이름으로 된 이미지 파일이 없으면 이미지 없는 걸로
             avatar,                  // 아바타 이미지명 (아이디)
            }, setUserState] = useRecoilState(userState);

    const onFinish = async (values) => {
        
        const request = await apiLogin.post(`/login`, values).then(res => res.data);

        console.log(request);
        if(request.loginSuccess) {
            // 세션스토리지에 저장
            sessionStorage.setItem('SSID', request.SSID);
            // recoil 상태변수에 상태값들을 저장한다.
            setLoginState({isLogin : request.loginSuccess, isAdmin: request.isAdmin, isSession : request.SSID});
            setUserState({
                userId : request.userId,
                userName : request.userName,
                admin : request.admin,
                userGroupId : request.userGroupId,
                department : request.department,
                postPosition : request.postPosition,
                onesDuty : request.onesDuty,
                territory : request.territory,
                birthday : request.birthday,
                employeeDate : request.employeeDate,
                mobile : request.mobile,
                gender : request.gender,
                shippingRight : request.shippingRight,
                stamp : request.stamp,
                avatar : request.avatar,
            });
            navigate('/');
        }
        else {
            Modal.error({
                title: '로그인 실패',
                content: '아이디나 패스워드가 달라 로그인에 실패하였습니다.', 
                onOk() { userInput.current.focus(); userInput.current.select(); },
            });
        }

    }

    const onFinishFailed = () => {}

    const contentStyle = {
        width: '700px',
        height: '500px',
        color: '#fff',
        lineHeight: '500px',
        textAlign: 'center',
        background: '#364d79',
        'border-top-left-radius' : '10px',
        'border-bottom-left-radius' : '10px',
    };

    const { Meta } = Card;

    return (
        <div className="Login">
        <div>
          <Row justify="center">
            <Title style={{marginTop: 80, marginBottom: 80, color:'white', 'text-shadow': '-2px 0px #666, 0px 2px #666, 2px 0px #666, 0px -2px #666'}}>(유)일신물산 주류도매업 관리 시스템</Title>
            {/* <img src={`../images/logo-login-basic.png`} align="center" 
                      valign="middle" vspace="80" /> */}
          </Row>
          <Row justify="center">
            <Card className='splash' 
                  style={{ width: 700, height: 500, margin: 0, borderTopColor : '#666', borderLeftColor: '#666', borderBottomColor: '#666', borderRightColor: '#fff',
                           borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
                  cover={                 
                    <Carousel autoplay style={{margin:0, padding: 0}}>
                      <div>
                        <img src="../images/login/global-beer.jpg" style={contentStyle} />
                      </div>
                      <div>
                        <img src="../images/login/Soju.jpg" style={contentStyle} />
                      </div>
                      <div>
                        <img src="../images/login/Whiskeys_Display.jpg" style={contentStyle} />
                      </div>
                      <div>
                        <img src="../images/login/wine.jpg" style={contentStyle} />
                      </div>
                    </Carousel>}
                  >
            </Card>
            <Card className='login'
                  style={{ width : 400, height: 500, margin: 0, overflow: "hidden", 
                           borderTopColor : '#666', borderLeftColor: '#ccc', borderBottomColor: '#666', borderRightColor: '#666', 
                           borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
                //   style={{ width : 400, height: 360, margin: 0, borderRadius: "10px", overflow: "hidden", 
                //            borderColor: 'rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow:'2px 2px 2px 1px rgba(0, 0, 0, 0.4)' }}
                  >
                    <br /><br /><br />
                      <Title style={{color:'#40a9ff'}}>Login</Title>
                    <br /><br />
              <Form className='login'
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off" >
                <Form.Item
                        label="아이디"
                        name="userid"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input placeholder="USER ID"
                           suffix={<UserOutlined className="site-form-item-icon" style={{color:'#aaa'}} />}
                           ref={userInput} />
                </Form.Item>
                <br />
                <Form.Item
                        label="비밀번호"
                        name="userpass"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input.Password placeholder="PASSWORD" />
                </Form.Item>
                <br />
                <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                    <Button type="primary" htmlType="submit"  style={{borderRadius:20, color:'white', width:120}}>
                    Login
                    </Button>
                </Form.Item>
              </Form>
              <br />
              <Checkbox>아이디 기억하기</Checkbox>
            </Card>
          </Row>
        </div>
        </div>
    );
}

export default Login;