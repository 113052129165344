import { notification, Modal } from 'antd';

//---------- antd notification setting ---------------//
notification.config({
    placement: 'bottomLeft',
    bottom: 50,
    duration: 3,
    rtl: false,
});

export const openNotification = (title, msg) => {
    notification.info({
      message: title,
      description: msg,
    });
};


export const openErrorModal = (title, msg) => {
    Modal.error({
        title: title,
        content: msg, 
        onOk() { 
        },
     });
};