import axios from 'axios';
import { AXIOS_HEADERS, WEB_API_URL, IMG_API_URL } from '../config/config';
import 'antd/dist/antd.css';
import '../index.css';

import { openErrorModal, openNotification } from './common';
import { useEffect, useState } from 'react';

export const apiLogin = axios.create({
    baseURL : `${WEB_API_URL}`,
    headers : AXIOS_HEADERS,
});

export const apiCall = axios.create({
    baseURL : `${WEB_API_URL}`,
    headers : AXIOS_HEADERS,
});

export const imageApiCall = axios.create({
    baseURL : `${IMG_API_URL}`,
    headers : AXIOS_HEADERS,
});

export const publicDataAPI = axios.create({
    baseURL : 'https://api.odcloud.kr/api/nts-businessman/v1',
    headers : AXIOS_HEADERS,
})

// apiCall 커스텀 axios가 호출되었을때 자동으로 bearer Authorization을 맞춰준다 
apiCall.interceptors.request.use( async function (config) {

    const SSID = sessionStorage.getItem('SSID');
    config.headers.Authorization = `Bearer ${SSID}`;

    return config;
})

export const apiCallInterceptorsNavigate = navigate => {

    const [ mode, setMode ] = useState(true);

    useEffect(() => {

        if(!mode) {

            openErrorModal('사용자 접속정보 만료', res.data.msg.long);
            sessionStorage.clear();
            navigate('/logout', {replace: true});

        }

    }, [mode])

    apiCall.interceptors.response.use( async function (res) {
        // 여기서 session 만료가 된 값이 들어오면 바로 메세지 띄워주고...
        // 세션 정리하고...
        // navigate로 login 페이지로 강제이동 시킨다.

        if(typeof(res.data) !== 'undefined') {
            if(typeof(res.data.loginSuccess) !== 'undefined') {
                if(!res.data.loginSuccess) {
                    setMode(false);
                }
                else {
                    return res;
                }
            }
            else {
                return res;
            }
        }
        else {
            return res;
        }

    })
};

