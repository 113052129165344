import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { apiCallInterceptorsNavigate } from './common-lib/custom-axios';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from './store/user-store';

import { ConfigProvider, notification } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MobileApp from './mobile/mobileapp';
import 'semantic-ui-css/semantic.min.css';

// function AxiosInterceptorsNavigate() {
//   let navigate = useNavigate();
//   apiCallInterceptorsNavigate(navigate);
//   return <></>;
// }

function UseLoginSessionChecker() {

  const navigate = useNavigate();

  const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);

  useEffect(() => {
    // isLogin == false 이면 메세지를 띄우고 Login 페이지로 이동하게 한다.

    if(!isLogin) {
      notification.info({
        message: `${isLogin}`,
        description: 'isLogin의 변화가 감지되었습니다.',
      });
    }

  }, [isLogin])

  return <></>;
}

ReactDOM.render(
    // <React.StrictMode>
      <RecoilRoot>
        <BrowserView>
          <BrowserRouter>
            {/* <AxiosInterceptorsNavigate /> */}
            <UseLoginSessionChecker />
              <ConfigProvider locale={koKR}>
                <App />
              </ConfigProvider>
          </BrowserRouter>
        </BrowserView>
        <MobileView>
          <MobileApp />
        </MobileView>
      </RecoilRoot>,
    // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();