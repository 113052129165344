import React, { useEffect, useState } from 'react';
import { useRoutes, Link, Outlet, useParams, useLinkClickHandler } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Divider } from 'antd';
import { UserOutlined, HomeFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';

//import Logout from './logout.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../store/user-store.js';

import { IMG_AVATAR_URL } from '../config/config';

function ScreenLayout() {

    const { SubMenu } = Menu;
    const { Header, Footer, Content, Sider } = Layout;
    const profileMenu = (
        <Menu>
            <Menu.Item key="98"><Link to="/myinfo">내 정보 보기</Link></Menu.Item>
            <Menu.Divider />
            <Menu.Item key="99"><Link to="/logout">로그아웃</Link></Menu.Item>
        </Menu>
    );

    const [ imgSrc, setImgSrc ] = useState('');

    const { 
        userId,
        userName,
        userArea,
        userOfficePosition,
        avatar,
    } = useRecoilValue(userState);

    let savedIndex = sessionStorage.getItem('menuIndex') ? sessionStorage.getItem('menuIndex'): '1';

    useEffect(() => {
        if(savedIndex == null) {
            savedIndex = '1';
        }
    }, [])

    useEffect(() => {
        if(avatar !== '')
            setImgSrc(IMG_AVATAR_URL + '/' + avatar);
    }, [avatar])

    const clickHandler = (e) => {
        sessionStorage.setItem('menuIndex', e.key)
    }

    const menuItems = [
        { label : <Link to="/">홈</Link>, key: '1', icon: <HomeFilled /> },
        { label : '공통업무', key: '2',
            children: [
                { label: <Link to="/notices">공지사항</Link>, key:'2-1' }, 
                { label: '업무일정관리', key:'2-2' }, 
                { label: '일일업무보고', key:'2-3' }, 
                { type: 'divider' },
                { label: '상품배송 요청', key:'2-4' }, 
                { label: '냉장고 수리요청', key:'2-5' }, 
                { type: 'divider' },
                { label: <Link to="/shipping">상품매입관리</Link>, key:'2-6' }, 
                { type: 'divider' },
                { label: '신규대상 영업관리', key:'2-7' }, 
            ],
        }, 
        { label : '판매팀업무', key: '4',
            children: [
                { label: '매출관리', key:'4-1' }, 
                { label: '미수관리', key:'4-2' }, 
                { label: '현금입금관리', key:'4-3' }, 
                { label: '거래처 요구사항', key:'4-4' }, 
                { label: '공용기채권 증가업소', key:'4-5' }, 
                { type: 'divider' },
                { label: '일일 업무보고', key:'4-6' }, 
            ],
        }, 
        { label : '영업팀업무', key: '5',
            children: [
                { label: '신규거래처 수익관리', key:'5-1' }, 
                { label: '불량업소 관리', key:'5-2' }, 
            ],
        }, 
        { label : '관리팀업무', key: '6',
            children: [
                { label: <Link to='/salesclients'>거래처 관리</Link>, key:'6-1' }, 
                { label: '신규거래처 관리', key:'6-2' }, 
                { label: <Link to='/businessnumber'>사업자 상태조회</Link>, key:'6-3' }, 
                { label: '급여계산', key:'6-4' }, 
                { label: '일일자금일지', key:'6-5' }, 
                { label: '거래처 전산 관리', key:'6-6' }, 
                { label: '외부 차입금 관리', key:'6-7' }, 
            ],
        }, 
        { label : '자산관리', key: '7',
            children: [
                { label: '냉장고 관리', key:'7-1' }, 
                { label: <Link to='/cars'>차량 관리</Link>, key:'7-2' }, 
                { label: '비품 관리', key:'7-3' }, 
            ],
        }, 
        { label : '경영자업무', key: '8',
            children: [
                { label: '전체 현황', key:'8-1' }, 
                { label: '매출 관리', key:'8-2' }, 
                { label: '미수 관리', key:'8-3' }, 
            ],
        }, 
        { label : '업무정보', key: '9',
            children: [
                { label: '업무 메뉴얼', key:'9-1' }, 
                { label: '취업규칙 및 각종 부칙', key:'9-2' }, 
                { label: '질문과 답변', key:'9-3' }, 
                { label: '각종 교육자료', key:'9-4' }, 
            ],
        }, 
    ];

    return ( 
        <Layout style={{minHeight: '100vh'}}>
            {/* <Row style={{width:'100%'}}  justify="space-between"> */}
            <Header className="header"  style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#fff'}}>
                <div style={{float:'right'}}>
                <div style={{float:'left'}}>{userName}님 환영합니다! &nbsp;&nbsp;</div>
                    <Dropdown overlay={profileMenu} placement="bottomRight" arrow overlayStyle={{margin: '-10px -10px 0px 0'}}>
                        <Avatar src={imgSrc} size={40} style={{ margin: '10px 24px 16px 0', cursor: 'pointer', backgroundColor: '#fa8c16' }} >{userName}</Avatar>
                    </Dropdown>
                </div>

                <Menu theme="white" mode="horizontal" onClick={clickHandler} selectable={true} defaultSelectedKeys={savedIndex} items={menuItems} />
                  {/* <Menu.Item key="1"><Link to="/">홈</Link></Menu.Item>
                  <Menu.Item key="2"></Menu.Item>
                  <Menu.Item key="5"><Link to="/users">사용자관리</Link></Menu.Item>
                  <Menu.Item key="2"><Link to="/usergroups">그룹관리</Link></Menu.Item>
                  <Menu.Item key="3"><Link to="/menus">메뉴관리</Link></Menu.Item>
                  <Menu.Item key="4"><Link to="/menupermission">메뉴권한관리</Link></Menu.Item>
                  <Menu.Item key="6"><Link to="/settings">기초설정</Link></Menu.Item>
                  <Menu.Item key="7"><Link to="/userlogs">사용자 로그관리</Link></Menu.Item> */}
            </Header>
            {/* </Row> */}
            <Layout>
                <Content style={{ padding: '0 50px', marginTop: 64}}>
                    <div className="main-panel" style={{ padding: 24, marginTop: 32, backgroundColor: '#fff', minHeight: '82vh'}}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>(유)일신물산 ©2022 Created & Copyright by 고영빈</Footer>
            </Layout>
        </Layout>
    );
}

export default ScreenLayout;