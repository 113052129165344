import { Typography, Row, Col, Tabs, Divider } from 'antd';

function ClientDetailsInfo (props) {

    const { Text } = Typography;
    const { TabPane } = Tabs;

    const clientDetails = (
        <>
        </>
    )

    return (
        <>
            {clientDetails}
        </>
    )

}

export default ClientDetailsInfo;