import React, { useEffect, useState } from 'react';
import { Row, Typography, Button, Tabs, Table, PageHeader, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../../store/user-store';
import { apiCall, publicDataAPI } from '../../../common-lib/custom-axios';
import axios from 'axios';

import { salesclients2_columns } from '../../tableDatas/salesclientsDatas';

const { Text } = Typography;
const { TabPane } =  Tabs;

message.config({
    top: parseInt((screen.availHeight/2) - (24/2)),
});

function BusinessNumber() {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        admin,                   // 관리자, 비관리자 (관리자 사이트 접속유무 등)
        userGroupId,             // 그룹 아이디 넘버
        department,              // 소속부서
        postPosition,            // 직위
        onesDuty,                // 직책
        territory,               // 담당구역
        birthday,                // 생일
        employeeDate,            // 입사일
        mobile,                  // 핸드폰
        gender,                  // 남성, 여성
        shippingRight,           // 배송을 할 수 있는 담당자 유무
        stamp,                  // 도장 이미지명                       // 두개는 없애도 될듯함. 특정 폴더에 아이디 이름으로 된 이미지 파일이 없으면 이미지 없는 걸로
        avatar,                  // 아바타 이미지명 (아이디)
    }, setUserState] = useRecoilState(userState);

    const [ clientsData, setClientsData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState(0);

    const getClientData = async () => {

        const hide = message.loading('휴폐업 정보를 읽어오고 있습니다. 잠시만 기다려주세요.', 1);

        setLoading(true);
        let res = await apiCall.get('/salesclients/10').then(res => res.data);

        let businessNumber = [];
        let i = 0;
        let j = 0;

        let max = 100;                                 // 페이지당 레코드
        let totalRecord = res.data.length;                  // 전체 데이터수
        let maxPage = Math.ceil(totalRecord / max);    // 전체 페이지 수
        let remainder = totalRecord % max;                  // 

        let startRecord = 0;
        let endRecord = 100;

        let page = 0;
        for( j = page; j < maxPage; j++) {

            if( j === (maxPage - 1)) {
                endRecord = remainder + startRecord;
                max = remainder;
            }

            for(i = startRecord; i < endRecord; i++) {
                businessNumber[i] = res.data[i].businessNumber.replace('-', '').replace('-', '');
            }
            
            businessNumber = businessNumber.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });

            //console.log(businessNumber)

            let data = { "b_no" : businessNumber };
            let jsonData = JSON.stringify(data);

            const res2 = await axios({
                method: 'post',
                url : 'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=6r073peqkUbvoxmNMkmu%2FJW2NnjoSkDeVKVHes3%2BzABMQBWfweIDB9kOat620qledyRBdX5EB3tAc0a4Lr%2B9%2Bg%3D%3D',
                headers : {
                    'Content-Type' : 'application/json;'
                },
                data : { "b_no" : businessNumber },
            }).then(res => res.data);

            let count = startRecord;
            for(i = 0; i < max; i++) {
                res.data[count].numberCheck = res2.data[i].b_stt;
                count++;
            }
                                
            startRecord = startRecord + 100;
            endRecord   = endRecord + 100;
            businessNumber = [];
        }   // for j

        setClientsData(res.data);
        setLoading(false);
    }

    const handleTabsChange = (e) => {
        if(e === '2') {
            if(clientsData === null) getClientData();
        }
    }

    const TitleHead = (
        <>
            <Row justify='center' align='middle'><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> &nbsp; 매출거래처 휴폐업 사업자 상태조회<div></div></Row>
        </>
    );

    return isLogin ? (
        <>
            <div className='BusinessNumber'>
                <Tabs tabPosition='top' onChange={handleTabsChange}>
                    <TabPane tab="사업자 진위조회 서비스" key="1">
                        <input name='businessNumber' /> <Button type="danger" style={{borderRadius:20}}>사업자 진위확인</Button>
                    </TabPane>
                    <TabPane tab="휴폐업 조회 서비스" key="2">
                        <PageHeader className="site-page-header" title={TitleHead} subTitle="현재 거래중인 매출거래처의 휴폐업을 조회합니다." 
                                    extra={[
                                        <Button type="primary" onClick={getClientData} style={{borderRadius:20}}>
                                            <FontAwesomeIcon icon={faMagnifyingGlassLocation} /> &nbsp; 상태조회하기
                                        </Button>,
                                    ]}
                        />
                        <Table dataSource={clientsData} columns={salesclients2_columns} size="small" className='intraTable' loading={loading} 
                               showSorterTooltip={{ title: '오름차순, 내림차순으로 정렬가능' }}
                               bordered={true} pagination={false} // pagination={{ showSizeChanger: false, pageSize: 13 }} 
                               onChange={page} 
                               scroll={{ y: 500 }}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </>
    ) : null;
}

export default BusinessNumber;