import React, { useEffect, useState, useRef } from 'react';
import { Typography, Card, Row, Col, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Divider } from '@blueprintjs/core';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getUserState, loginState, userState } from '../../../../store/user-store';

import { apiCall } from '../../../../common-lib/custom-axios';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import ApexChart from 'react-apexcharts';

const { Text } = Typography;

function ShippingStatistics() {

    const [ { isLogin, isAdmin, isSession }, setLoginState] = useRecoilState(loginState);
    const [ { userId,
              userName,
              admin,                   // 관리자, 비관리자 (관리자 사이트 접속유무 등)
              userGroupId,             // 그룹 아이디 넘버
              department,              // 소속부서
              postPosition,            // 직위
              onesDuty,                // 직책
              territory,               // 담당구역
              birthday,                // 생일
              employeeDate,            // 입사일
              mobile,                  // 핸드폰
              gender,                  // 남성, 여성
              shippingRight,           // 배송을 할 수 있는 담당자 유무
              stamp,                   // 도장 이미지명                       // 두개는 없애도 될듯함. 특정 폴더에 아이디 이름으로 된 이미지 파일이 없으면 이미지 없는 걸로
              avatar,                  // 아바타 이미지명 (아이디)
            }, setUserState] = useRecoilState(userState);


    const [ chart1Data, setChart1Data ] = useState({
      series: [] ,
      comma: [],
      chart: {
          width: 380,
          type: 'pie',
      },
      labels: [], 
      legend: { position : 'bottom', horizontalAlign: 'left' },
      responsive: [{
          breakpoint: 480,
              options: {
                  chart: {width: 200},
                  legend: { position: 'bottom'}
              }
      }]
    });

    const [ chart2Data, setChart2Data ] = useState({
      series: [] ,
      chart: {
          width: 380,
          type: 'pie',
      },
      labels: [], 
      legend: { position : 'bottom' },
      responsive: [{
          breakpoint: 480,
              options: {
                  chart: {width: 200},
                  legend: { position: 'bottom'}
              }
      }]
    });
    const [ chart3Data, setChart3Data ] = useState({});

    useEffect(() => {
      getChartDatas(1);
      getChartDatas(2);
      getChartDatas(3);
    }, []);

    useEffect(() => {
      console.log('차트3', chart3Data)
    }, [chart3Data])

    const getChartDatas = async (values) => {
        var res = await apiCall.get(`/shipping/charts/${values}`).then( res => res.data );

        if(values === 1) {
                setChart1Data({
                  series: [ parseInt(res.data[0].TT), parseInt(res.data[1].TT), parseInt(res.data[2].TT), parseInt(res.data[3].TT), parseInt(res.data[4].TT) ],
                  comma : [ res.data[0].thousandComma, res.data[1].thousandComma, res.data[2].thousandComma, res.data[3].thousandComma, res.data[4].thousandComma ],
                  chart: {
                      width: 380,
                      type: 'pie',
                  },
                  labels: [ res.data[0].statementName, res.data[1].statementName, res.data[2].statementName, res.data[3].statementName, res.data[4].statementName ], 
                  legend: { position : 'left', horizontalAlign: 'left' },
                  responsive: [{
                      breakpoint: 480,
                          options: {
                              chart: {width: 200},
                              legend: { position: 'bottom'}
                          }
                  }]
                });
        }

        if(values === 2) {
          setChart2Data({
            series: [ parseInt(res.data.shippingSelf), parseInt(res.data.shippingOther) ],
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [ '자차', '용차' ], 
            legend: { position : 'left' },
            responsive: [{
                breakpoint: 480,
                    options: {
                        chart: {width: 200},
                        legend: { position: 'bottom'}
                    }
            }]
          });
        }

        if(values === 3) {
          setChart3Data(res.data);
        }
      }

    const gridStyle = {
      width: '60%',
      textAlign: 'center',
    };

    const gridStyle2 = {
      width: '40%',
      textAlign: 'right',
    };

    return (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <Card  style={{borderRadius: 12}} title="제조사별 매입량 비율 상위 TOP 5">
                <ApexChart
                  options={chart1Data}
                  series={chart1Data.series}
                  type="donut"
                  width="525"
                />
              </Card>
            </Col>
            <Col span={5}>
                <Card  style={{borderRadius: 12}} title="제조사별 결제 매입금액 비율 상위 TOP 5"> 
                {/* chart1Data.comma[0].thousandComma */}
                  <Card.Grid style={gridStyle}>{chart1Data.labels[0]}</Card.Grid><Card.Grid style={gridStyle2}>{chart1Data.comma[0]}원</Card.Grid>
                  <Card.Grid style={gridStyle}>{chart1Data.labels[1]}</Card.Grid><Card.Grid style={gridStyle2}>{chart1Data.comma[1]}원</Card.Grid>
                  <Card.Grid style={gridStyle}>{chart1Data.labels[2]}</Card.Grid><Card.Grid style={gridStyle2}>{chart1Data.comma[2]}원</Card.Grid>
                  <Card.Grid style={gridStyle}>{chart1Data.labels[3]}</Card.Grid><Card.Grid style={gridStyle2}>{chart1Data.comma[3]}원</Card.Grid>
                  <Card.Grid style={gridStyle}>{chart1Data.labels[4]}</Card.Grid><Card.Grid style={gridStyle2}>{chart1Data.comma[4]}원</Card.Grid>
                </Card>
            </Col>
            <Col span={6}>
                <Card  style={{borderRadius: 12}} title="자차 / 용차 배송 비율">
                <ApexChart
                  options={chart2Data}
                  series={chart2Data.series}
                  type="pie"
                  width="410"
                />
                </Card>
            </Col>
            <Col span={5}>
                <Card  style={{borderRadius: 12}} title="배송 건수 자료">
                  <Card.Grid style={gridStyle}>총 배송건수</Card.Grid><Card.Grid style={gridStyle2}>{chart3Data.totalCount} 건</Card.Grid>
                  <Card.Grid style={gridStyle}>자차 상품배송</Card.Grid><Card.Grid style={gridStyle2}>{chart3Data.shippingGoods} 건</Card.Grid>
                  <Card.Grid style={gridStyle}>자차 공병배송</Card.Grid><Card.Grid style={gridStyle2}>{chart3Data.shippingBottle} 건</Card.Grid>
                  <Card.Grid style={gridStyle}>제조사 배송</Card.Grid><Card.Grid style={gridStyle2}>{chart3Data.others} 건</Card.Grid>
                  <Card.Grid style={gridStyle}>거래한 제조사 갯수</Card.Grid><Card.Grid style={gridStyle2}>{chart3Data.cnt} 곳</Card.Grid>
                </Card>
            </Col>
          </Row>
        </>
    )
}

export default ShippingStatistics;