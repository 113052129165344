//import logo from './logo.svg';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Classes, Dialog, HotkeysProvider, HotkeysTarget2, Button } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import './App.css';
import { useRoutes, useLocation } from 'react-router-dom';

import idleTIme from './common-lib/idleTimer';

import ScreenLayout from './pages/screenlayout';
import Login from './pages/login';
import Logout from './pages/logout';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loginState } from './store/user-store.js';

import AuthCheck from './pages/auth/authcheck';
//import PermissionCheck from './pages/auth/permissioncheck';

import Home from './pages/home';
import Myinfo from './pages/myinfo';

// 공지사항
import Notices from './pages/subpages/jobmanagement/notices';

import SalesClients from './pages/subpages/management/salesclients';

// 상품매입관리
import Shipping from './pages/subpages/common/shipping';
import BusinessNumber from './pages/subpages/management/businessnumber';

/*-------------- 자산관리 ---------------------*/
// 차량관리
import Cars from './pages/subpages/assetmanagement/cars';

import Page404 from './pages/page404';

let timer;

function App() {

  let navigate = useNavigate();

  const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);

 // Refresh가 눌렸을때 isLogin값이 초기화 되어 있을때 작동하도록 한다.
  const [ isTimeout, setIsTimeout ] = useState(false);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ currDialog, setCurrDialog ] = useState('nothing');
  //const [ timer, setTimer ] = useState(new idleTIme);

  function SessionHookCapture() {
    useEffect(() => {
      if(!isSession) {
        console.log('튕겨랏')                                                    
      }
    }, [isSession])

  }
  
  const appHotkeys = [
    {
      combo: 'F4',
      global: true,
      onKeyDown : (e) => { if(currDialog === 'F4Dialog' || currDialog === 'nothing' ) {setCurrDialog('F4Dialog'); toggleDialog(e.code);} else toggleDialog(e.code); }
    }
  ];

  useEffect(() => {
    if(location.pathname !== '/login') {
        timer = new idleTIme({
          timeout: 300,
          onTimeout: () => {
            setIsTimeout(true);

            if(currDialog === 'nothing') { 
              setCurrDialog('timeoutDialog'); 
              toggleDialog(null);
            }
          },
          onExpired: () => {
            // do something if expired on load
            setIsTimeout(true);
          }
        });

        return () => { timer.cleanUp();}
    }
  }, [isTimeout, location.pathname, currDialog])

  const routes = [
    { 
      element : <AuthCheck isLogin={true} isAdmin={true}><ScreenLayout /></AuthCheck>, 
      path : '/',
      children : [
        { index : true, element : <AuthCheck isLogin={true}><Home /></AuthCheck>, },
        { element : <AuthCheck isLogin={true}><Home /></AuthCheck>,     path : '/home',},
        { element : <AuthCheck isLogin={true}><Notices /></AuthCheck>,  path : '/notices', },
        { element : <AuthCheck isLogin={true}><Shipping /></AuthCheck>, path : '/shipping', },
        { element : <AuthCheck isLogin={true}><SalesClients /></AuthCheck>, path : '/SalesClients', },
        { element : <AuthCheck isLogin={true}><BusinessNumber /></AuthCheck>, path : '/businessnumber', },
        { element : <AuthCheck isLogin={true}><Cars /></AuthCheck>, path : '/cars', },
        { element : <Myinfo />,   path : '/myinfo', },
        { element : <Logout />, path : '/logout', },
        { element :<Page404 />, path : '*', }
      ],
    },
    { element : <Login />,  path: '/login' },
  ]

  const element = useRoutes(routes);

  const toggleDialog = (key) => {

    if(key === 'F4') {
      if(currDialog === 'nothing') {
          setCurrDialog('F4Dialog');
          setShowDialog( prevState => !prevState);
        }
      else if(currDialog !== 'nothing') {
          if(showDialog) {
              setCurrDialog('nothing');
              setShowDialog( prevState => !prevState);
      
          }
      }
    }

    if(key === null) {
      if(currDialog === 'nothing') {
          setCurrDialog('timeoutDialog');
          setShowDialog(prevState => !prevState);
      }
      else if(currDialog !== 'nothing') {
          if(showDialog) {
              setCurrDialog('nothing');
              setShowDialog(prevState => !prevState);
          }
      }
    }

  }

  const TimeoutDialog = (
    <>
          <Dialog isOpen={showDialog} title="자동 로그아웃하여 화면 이동합니다." >
            <div className={Classes.DIALOG_BODY}>
              5분동안 아무런 입력이나 동작이 없어서 로그인 화면으로 이동합니다.<br /><br />
              <div style={{textAlign:'center'}}>
                <Button onClick={() => {
                    sessionStorage.clear(); 
                    navigate('/login'); 
                    toggleDialog(null);
                    }} intent="danger" text="로그인으로 이동" />
              </div>
            </div>
          </Dialog>
    </>
  )

  const F4Dialog = (
    <>
      { location.pathname !== '/login' && (
          <Dialog isOpen={showDialog} title="인트라넷 관리자 화면잠금!!" style={{width: 500, height: 400}} >
            <div className={Classes.DIALOG_BODY} style={{textAlign:'center'}}>
              <img src="./images/kungfu.png" vspace={30} />
            </div>
          </Dialog>
       )}
    </>
  )

  return (
    <HotkeysProvider>
      <HotkeysTarget2 hotkeys={appHotkeys}>
        {currDialog === 'nothing' && null }
        {currDialog === 'F4Dialog' && F4Dialog }
        {currDialog === 'timeoutDialog' && TimeoutDialog}
            <div className="App">
              { element }
            </div>
            {/* <ReactDimmer isOpen={showDialog} zIndex={10} exitDimmer={setShowDialog} blur={7} /> */}
      </HotkeysTarget2>
    </HotkeysProvider>
  );
}

export default App;