export const noticesList_columns = [
    {
        title: '공지번호',
        dataIndex: 'id',
        width: 120,
        align: 'center',
        key: 'id',
    },
    {
        title: '공지대상',
        dataIndex: 'department',
        width: 200,
        align: 'center',
        key: 'department',
    },
    {
        title: '작성일',
        dataIndex: 'wdate2',
        width: 200,
        align: 'center',
        key: 'wdate2',
    },
    {
        title: '제목',
        dataIndex: 'subject',
        width: 500,
        align: 'left',
        key: 'subject',
    },
    {
        title: '작성자',
        dataIndex: 'writer',
        width: 200,
        align: 'center',
        key: 'writer',
    },
];

