export const WEB_URL = 'https://intranet.eilsin.co.kr';     // intraweb.eilsin.co.kr
export const MOBILE_URL = 'https://test2.eilsin.co.kr';     // m.eilsin.co.kr
export const ADMIN_URL = 'https://admin.eilsin.co.kr';      // admin.eilsin.co.kr

export const WEB_API_URL  = 'https://api-web.eilsin.co.kr';        // api-web.eilsin.co.kr        - 웹용 인트라넷 주소
export const MOBILE_API_URL  = 'https://api-mobile.eilsin.co.kr';  // api-mobile.eilsin.co.kr     - 모바일용 인트라넷 주소
export const ADMIN_API_URL  = 'https://api-admin.eilsin.co.kr';    // api-admin.eilsin.co.kr      - 관리자 설정용 인트라넷 주소


export const IMG_API_URL  = 'https://images.eilsin.co.kr';
export const IMG_AVATAR_URL = 'https://images.eilsin.co.kr/avatars';
export const IMG_STAMP_URL = 'https://images.eilsin.co.kr/stamps';

const AXIOS_HEADERS = {'Accept' : 'application/json', 'Content-Type' : 'application/json;'};