import { useEffect, useState } from 'react';
import { PageHeader, Row, Col, Table, Button, DatePicker, Space, Popconfirm } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { Divider } from '@blueprintjs/core';

import { useForm, Controller, set } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { apiCall } from '../../../../common-lib/custom-axios';

import moment from 'moment';
import 'moment/locale/ko';

import './../../../../css/input.css';
import './../../../../css/refuel-picker-input.css';



function RefuelInfo(props) {

    const { register, setValue, getValues, handleSubmit, reset, control, formState: {errors} } = useForm();

    const [ fuelData, setFuelData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ mode, setMode ] = useState('add');
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ members, setMembers ] = useState([]);
    const [ getMonth, setGetMonth ] = useState(moment());

    const dateFormat = 'YYYY-MM-DD';
    const monthFormat = 'YYYY-MM';
    const today = moment();

    const operationCell = 
    {
        title: '삭제',
        dataIndex: '',
        align: 'center',
        render: (_, record) => 
            (
                <>
                    <Popconfirm title="선택한 행을 삭제하시겠습니까 ?" style={{width:400}}
                            icon={<DeleteFilled style={{color:'#ff7875'}} />}
                            onConfirm={(e) => {e.stopPropagation(); console.log(e); handleDelRow(record.refuelId, record.carId)}}
                            onCancel={(e) => e.stopPropagation()}
                            placement="topRight"
                            okText="예" cancelText="아니오">
                        <a onClick={e => {e.stopPropagation()}}><FontAwesomeIcon  icon={faTrashCan} style={{color:'#ff7875'}} /></a>
                    </Popconfirm>
                </>
            )
    };

    useEffect(() => {
      if(props.columns.length === 8 )
          props.columns.pop(null);

      if(props.columns.length < 8)
          props.columns.push( operationCell );

      getMembersList();
    }, [])

    useEffect(() => {
        setFuelData(props.dataSource);

        reset({
          refuelDate: today,
          oiler: '',
          gasStation: '',
          gasType: '',
          fuelVolume: '',
          fuelAmount: ''
        });

        console.log(props)

        setMode('add');
        setGetMonth(moment());
    }, [props.dataSource])

    const getMembersList = async () => {
        const res = await apiCall.get('/userlist').then(res => res.data);

        console.log('members', res.data)
        setMembers(res.data);
    } 

    const handleOnRow = (record) => {
      console.log(record)
      reset({
        refuelId: record.refuelId,
        rowNumber: record.rowNumber,
        refuelDate: record.refuelDate,
        oiler: record.oiler,
        gasStation: record.gasStation,
        gasType: record.gasType,
        fuelVolume: record.fuelVolume,
        fuelAmount: record.fuelAmount
      })
    }

    const onSubmit = async (values) => {
        
        reset({
          refuelDate: today,
          oiler: '',
          gasStation: '',
          gasType: '',
          fuelVolume: '',
          fuelAmount: ''
        })

        values.refuelDate = moment(values.refuelDate).format(dateFormat); // date 형식을 변환해준다.

        if(mode === 'add') {
            setLoading(true);
            // let tmpData = [];
            const res = await apiCall.post('/cars/refuel', {values}).then(res => res.data);

            console.log(res)

            // 기록에 성공한것을 확인하고 화면을 갱신한다.

            // values.rowNumber = fuelData.length + 1;
            // tmpData = fuelData;
            // tmpData[fuelData.length] = values;
            // setFuelData([...tmpData]);

            const res2 = await apiCall.get('/cardetails', {params : { 'mode' : '1', 'id' : props.id, 'sMonth' : moment(getMonth).format(dateFormat) }}).then(res => res.data);
            setFuelData(res2.data);

            setLoading(false);
        }   
        else if ( mode === 'edit') {
            setLoading(true);
            // let tmpData = fuelData;

            // values.prevDate = currentRecord.refuelDate;
            // values.prevOiler = currentRecord.oiler;
            console.log(values)
            
            const res = await apiCall.put(`/cars/refuel/${values.refuelId}`, {values}).then(res => res.data);

            console.log(res);

            const res2 = await apiCall.get('/cardetails', {params : { 'mode' : '1', 'id' : props.id, 'sMonth' : moment(getMonth).format(dateFormat) }}).then(res => res.data);
            console.log(res2);
            setFuelData(res2.data);

            // tmpData[values.rowNumber - 1] = values;
            // setFuelData([...tmpData]);
            setMode('add');

            setLoading(false);
        }
    }

    const handleDelRow = async (id, carId) => {
        const res = await apiCall.delete(`/cars/refuel/${id}`).then(res => res.data);
        const res2 = props.getCarDetails(1, carId).then(res=>res.data);
    }

    const handleCancel = () => {
      reset({
        refuelDate: moment(),
        oiler: '',
        gasStation: '',
        gasType: '',
        fuelVolume: '',
        fuelAmount: ''
      })
      setMode('add');
    }

    const handleChange = async (date, dateString) => {
      setGetMonth(date);
      const res = await apiCall.get('/cardetails', {params : { 'mode' : '1', 'id' : props.id, 'sMonth' : dateString }}).then(res => res.data);
      setFuelData(res.data);
      console.log(res)
    }

    const queryMonth = (
      <>
        <DatePicker picker="month" allowClear={false} format={monthFormat} onChange={handleChange} value={moment(getMonth)} placeholder='검색 월 선택' />
      </>
    )

    return (
        <>
              <PageHeader className="site-page-header" title={props.title} subTitle="차량별 연료 주유 내역을 관리합니다." 
                  extra={[queryMonth]}
              />
              <Table locale={props.locale} dataSource={fuelData} /*carsRefuelingHistory */ columns={props.columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 8}} 
                     onRow={(record) => { return {
                      onClick: () => {setMode('edit'); setCurrentRecord(record); handleOnRow(record)},
                     }; }}

                     summary={ (pageData) => {

                      let tmpVolume = 0;
                      let tmpAmount = 0;

                      pageData.forEach(({ fuelVolume, fuelAmount }) => {
                            console.log(fuelVolume)
                            if(fuelVolume !== null) { 
                              fuelVolume = fuelVolume.replace(' ℓ', ''); 
                              tmpVolume += parseInt(fuelVolume);
                            }

                            if(fuelAmount !== null) { 
                              fuelAmount = fuelAmount.replace(/,/g, "");
                              tmpAmount += parseInt(fuelAmount);
                            }
                      });

                      let _tmpVolume = '';
                      let _tmpAmount = '';
                      _tmpVolume = tmpVolume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      _tmpAmount = tmpAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                      return (
                        <>
                          <Table.Summary.Row style={{backgroundColor:'#ebf6f6'}}>
                          <Table.Summary.Cell index={0} colSpan={3} align='right'><div  style={{color:'red', fontSize:'10pt'}}>현재 총 주유량 &nbsp;&nbsp;&nbsp;&nbsp;</div></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={1} align='center'><div  style={{color:'red', fontSize:'10pt'}}>{_tmpVolume} ℓ</div></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2} align='right'><div  style={{color:'red', fontSize:'10pt'}}>현재 총 금액 &nbsp;&nbsp;&nbsp;&nbsp;</div></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2} align='center'><div  style={{color:'red', fontSize:'10pt'}}>{_tmpAmount} 원</div></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      )
                     }}
              />
              <Divider />
              <PageHeader className="site-page-header" title={<><FontAwesomeIcon icon={faGasPump} style={{color:'#ff7875'}} /> 주유내역 입력</>} subTitle="주유 내역 입력하기"/>
              <form onSubmit={handleSubmit(onSubmit)} className="mycustom">
                  <input {...register('refuelId')} hidden />
                  <input {...register('carId')} value={props.id} hidden />
                  <input {...register('rowNumber')} hidden />
                  <Row align="middle">
                    <Col span={2} align="right">주유일자 &nbsp;&nbsp;</Col>
                    <Col span={6} ><Controller control={control} name='refuelDate' rules={{ required: true }} render={({ field: { onChange, onBlur, value, ref}}) => (
                                      <DatePicker allowClear={false} format={dateFormat} onChange={onChange} value={moment(value)} placeholder='날짜 선택' />
                                   )} /></Col><Col span={0}></Col>
                    <Col span={2} align="right">주 유 자 &nbsp;&nbsp;</Col>
                    <Col span={5} >
                        <select {...register('oiler', { required: true })} style={{width:206}} className="optionColor">
                            <option value="" disabled selected>주유자</option>
                            {
                                members.map(member => ( <option value={member.userName} className="optionColor" key={member.id} >{member.userName}</option>))
                            }
                        </select>
                    </Col><Col span={1}></Col>
                    <Col span={2} align="right">주 유 소 &nbsp;&nbsp;</Col>
                    <Col span={5} >
                        <select {...register('gasStation', { required: true })} className="optionColor" style={{width:206}}>
                          <option value="" disabled selected>주유소</option>
                          <option value="신도시주유소">신도시주유소</option>
                          <option value="계약외 주유수">계약외 주유소</option>
                        </select>
                    </Col><Col span={1}></Col>
                  </Row>
                  <Row align="middle">
                    <Col span={2} align="right">연 료 &nbsp;&nbsp;</Col>
                    <Col span={5} >
                      <select {...register('gasType', { required: true })} className="optionColor" style={{width:206}}>
                          <option value="" disabled selected>연료 종류</option>
                          <option value="휘발유">휘발유</option>
                          <option value="경유">경유</option>
                      </select>
                    </Col><Col span={1}></Col>
                    <Col span={2} align="right">주 유 량 &nbsp;&nbsp;</Col>
                    <Col span={5} ><input {...register('fuelVolume', {required : true} )} /></Col><Col span={1}></Col>
                    <Col span={2} align="right">주유금액 &nbsp;&nbsp;</Col>
                    <Col span={5} ><input {...register('fuelAmount')} /></Col><Col span={1}></Col>
                  </Row>
              </form><br />
              <Row justify='center'>
                <Space size={34}>
                  <Button onClick={handleCancel} type="warning" danger style={{borderRadius:20, width:100}}>취 소</Button>
                  <Button onClick={handleSubmit(onSubmit)} type="primary" danger style={{borderRadius:20, color:'white', width:160}}>주유내역 등록 / 수정</Button>
                </Space>
              </Row>
        </>
    );
}

export default RefuelInfo;