import { Popconfirm, Space, Tooltip, Typography } from 'antd';
import { EditFilled, DeleteFilled, FileImageOutlined, CloseOutlined, ArrowRightOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { apiCall } from '../../common-lib/custom-axios';

const { Text } = Typography;

const thousand_comma = (data) => {
    let _data = '' + data;
    _data = _data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return(_data);
}

export const shipping_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 60,
        align: 'center',
        key: 'id',
    },
    {
        title: '등록자',
        dataIndex: 'registant',
        width: 80,
        align: 'center',
        key: 'registant',
    },
    {
        title: '배송일자',
        dataIndex: 'ddate',
        width: 80,
        align: 'center',
        key: 'ddate',
    },
    {
        title: '배송물',
        dataIndex: 'goodsType',
        width: 70,
        align: 'center',
        key: 'goodsType',
        render : (text, record) => {
            let fontColor = '#40a9ff';

            if(record.goodsType === '상품')
                fontColor = '#ff7875';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text></div>
            </>
            )
        }
    },
    {
        title: '배송지',
        dataIndex: 'shippingAddress',
        width: 200,
        align: 'center',
        key: 'shippingAddress',
        render: (_, record) => (
                <><Text>{record.startingPoint} <ArrowRightOutlined style={{color:'#40a9ff'}} /> {record.destination}</Text></>
        ),
    },
    {
        title: '배송형태',
        dataIndex: 'shippingType',
        width: 80,
        align: 'center',
        key: 'shippingType',
    },
    {
        title: '배송자',
        dataIndex: 'shipper',
        width: 80,
        align: 'center',
        key: 'shipper',
    },
    {
        title: '배송내용',
        dataIndex: 'contents',
        width: 360,
        align: 'center',
        key: 'contents',
    },
    {
        title: '배송사진',
        dataIndex: 'shippingImg',
        width: 80,
        align: 'center',
        key: 'shippingImg',
        render: (_, record) => (
            record.shippingImg ? <><FileImageOutlined style={{color:'#40a9ff', fontSize: '18px'}} /></> : <><CloseOutlined style={{color:'#ff7875', fontSize: '18px'}} /></>
        )
    },
    // {
    //     title: '작업메뉴',
    //     dataIndex: '',
    //     align: 'center',
    //     width: 140,
    //     // render: (_, record) => 
    //     // (
    //     //     <>
    //     //         <Space size={32}>
    //     //         <Popconfirm title="선택한 행을 수정하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); console.log('액션시작'); editShipping(record);}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => e.stopPropagation()}><EditFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
  
    //     //         <Popconfirm title="선택한 행을 삭제하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); delShipping(record.id)}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => {e.stopPropagation()}}><DeleteFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
    //     //         </Space>
    //     //     </>
    //     // )
    // },
];

export const computerized_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 80,
        align: 'center',
        key: 'id',
    },
    {
        title: '배송일자',
        dataIndex: 'ddate',
        width: 120,
        align: 'center',
        key: 'ddate',
    },
    {
        title: '전표일자',
        dataIndex: 'dateOfIssue',
        width: 120,
        align: 'center',
        key: 'dateOfIssue',
    },
    {
        title: '배송물',
        dataIndex: 'goodsType',
        width: 100,
        align: 'center',
        key: 'goodsType',
        render : (text, record) => {
            let fontColor = '#40a9ff';

            if(record.goodsType === '상품')
                fontColor = '#ff7875';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text></div>
            </>
            )
        }
    },
    {
        title: '배송지',
        dataIndex: 'shippingAddress',
        width: 200,
        align: 'center',
        key: 'shippingAddress',
        render: (_, record) => (
                <><Text>{record.startingPoint} <ArrowRightOutlined style={{color:'#40a9ff'}} /> {record.destination}</Text></>
        ),
    },
    {
        title: '전표발행처',
        dataIndex: 'statementName',
        width: 180,
        align: 'center',
        key: 'statementName',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 14) {
                    dtext = text.substr(0, 14) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '배송형태',
        dataIndex: 'shippingType',
        width: 120,
        align: 'center',
        key: 'shippingType',
    },
    {
        title: '배송자',
        dataIndex: 'shipper',
        width: 120,
        align: 'center',
        key: 'shipper',
    },
    {
        title: '배송내용',
        dataIndex: 'contents',
        width: 360,
        align: 'center',
        key: 'contents',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 30) {
                    dtext = text.substr(0, 30) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '전표확인',
        dataIndex: 'statementChecker',
        width: 120,
        align: 'center',
        key: 'statementChecker',
    },
    {
        title: '결제금액',
        dataIndex: 'totalAmount2',
        width: 120,
        align: 'right',
        key: 'totalAmount2',
        render : (text, record) => {
            let fontColor = 'blue';

            if(record.goodsType === '상품')
                fontColor = 'red';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text> 원</div>
            </>
            )
        }
    },
    // {
    //     title: '작업메뉴',
    //     dataIndex: '',
    //     align: 'center',
    //     width: 140,
    //     // render: (_, record) => 
    //     // (
    //     //     <>
    //     //         <Space size={32}>
    //     //         <Popconfirm title="선택한 행을 수정하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); console.log('액션시작'); editShipping(record);}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => e.stopPropagation()}><EditFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
  
    //     //         <Popconfirm title="선택한 행을 삭제하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); delShipping(record.id)}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => {e.stopPropagation()}}><DeleteFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
    //     //         </Space>
    //     //     </>
    //     // )
    // },
];

export const payment_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 80,
        align: 'center',
        key: 'id',
    },
    {
        title: '배송일자',
        dataIndex: 'ddate',
        width: 90,
        align: 'center',
        key: 'ddate',
    },
    {
        title: '전표일자',
        dataIndex: 'dateOfIssue',
        width: 90,
        align: 'center',
        key: 'dateOfIssue',
    },
    {
        title: '배송물',
        dataIndex: 'goodsType',
        width: 70,
        align: 'center',
        key: 'goodsType',
        render : (text, record) => {
            let fontColor = '#40a9ff';

            if(record.goodsType === '상품')
                fontColor = '#ff7875';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text></div>
            </>
            )
        }
    },
    {
        title: '배송지',
        dataIndex: 'shippingAddress',
        width: 200,
        align: 'center',
        key: 'shippingAddress',
        render: (_, record) => (
                <><Text>{record.startingPoint} <ArrowRightOutlined style={{color:'#40a9ff'}} /> {record.destination}</Text></>
        ),
    },
    {
        title: '전표발행처',
        dataIndex: 'statementName',
        width: 200,
        align: 'center',
        key: 'statementName',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 12) {
                    dtext = text.substr(0, 12) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '배송형태',
        dataIndex: 'shippingType',
        width: 70,
        align: 'center',
        key: 'shippingType',
    },
    {
        title: '배송자',
        dataIndex: 'shipper',
        width: 100,
        align: 'center',
        key: 'shipper',
    },
    {
        title: '배송내용',
        dataIndex: 'contents',
        width: 400,
        align: 'center',
        key: 'contents',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 36) {
                    dtext = text.substr(0, 36) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '전표확인',
        dataIndex: 'statementChecker',
        width: 100,
        align: 'center',
        key: 'statementChecker',
    },
    {
        title: '결제금액',
        dataIndex: 'totalAmount2',
        width: 120,
        align: 'right',
        key: 'totalAmount2',
        render : (text, record) => {
            let fontColor = 'blue';

            if(record.goodsType === '상품')
                fontColor = 'red';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text> 원</div>
            </>
            )
        }
    },
    {
        title: '전산입력',
        dataIndex: 'computerizedChecker',
        width: 100,
        align: 'center',
        key: 'computerizedChecker',
    },
    // {
    //     title: '작업메뉴',
    //     dataIndex: '',
    //     align: 'center',
    //     width: 140,
    //     // render: (_, record) => 
    //     // (
    //     //     <>
    //     //         <Space size={32}>
    //     //         <Popconfirm title="선택한 행을 수정하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); console.log('액션시작'); editShipping(record);}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => e.stopPropagation()}><EditFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
  
    //     //         <Popconfirm title="선택한 행을 삭제하시겠습니까 ?"
    //     //                 onConfirm={(e) => {e.stopPropagation(); delShipping(record.id)}}
    //     //                 onCancel={(e) => e.stopPropagation()}
    //     //                 placement="topRight"
    //     //                 okText="예" cancelText="아니오">
    //     //             <a onClick={e => {e.stopPropagation()}}><DeleteFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
    //     //         </Popconfirm>
    //     //         </Space>
    //     //     </>
    //     // )
    // },
];

export const completion_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 60,
        align: 'center',
        key: 'id',
    },
    {
        title: '배송일자',
        dataIndex: 'ddate',
        width: 100,
        align: 'center',
        key: 'ddate',
    },
    {
        title: '전표일자',
        dataIndex: 'dateOfIssue',
        width: 120,
        align: 'center',
        key: 'dateOfIssue',
    },
    {
        title: '배송물',
        dataIndex: 'goodsType',
        width: 60,
        align: 'center',
        key: 'goodsType',
        render : (text, record) => {
            let fontColor = '#40a9ff';

            if(record.goodsType === '상품')
                fontColor = '#ff7875';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text></div>
            </>
            )
        }
    },
    {
        title: '배송지',
        dataIndex: 'shippingAddress',
        width: 200,
        align: 'center',
        key: 'shippingAddress',
        render: (_, record) => (
                <><Text>{record.startingPoint} <ArrowRightOutlined style={{color:'#40a9ff'}} /> {record.destination}</Text></>
        ),
    },
    {
        title: '전표발행처',
        dataIndex: 'statementName',
        width: 180,
        align: 'center',
        key: 'statementName',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 12) {
                    dtext = text.substr(0, 12) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '배송자',
        dataIndex: 'shipper',
        width: 100,
        align: 'center',
        key: 'shipper',
    },
    {
        title: '배송내용',
        dataIndex: 'contents',
        width: 360,
        align: 'center',
        key: 'contents',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 32) {
                    dtext = text.substr(0, 32) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '전표확인',
        dataIndex: 'statementChecker',
        width: 100,
        align: 'center',
        key: 'statementChecker',
    },
    {
        title: '결제금액',
        dataIndex: 'totalAmount2',
        width: 100,
        align: 'right',
        key: 'totalAmount2',
        render : (text, record) => {
            let fontColor = 'blue';

            if(record.goodsType === '상품')
                fontColor = 'red';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text> 원</div>
            </>
            )
        }
    },
    {
        title: '전산입력',
        dataIndex: 'computerizedChecker',
        width: 90,
        align: 'center',
        key: 'computerizedChecker',
    },
    {
        title: '결제일',
        dataIndex: 'paymentDate',
        width: 90,
        align: 'center',
        key: 'paymentDate',
    },
    {
        title: '결제확인',
        dataIndex: 'paymentChecker',
        width: 100,
        align: 'center',
        key: 'paymentChecker',
    },
];