import { useEffect, useState, Fragment } from 'react';
import { Typography, PageHeader, Table, Row, Col, Input, Drawer, Tabs, Radio, Button, Statistic } from 'antd';
import { Divider } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faStoreSlash, faShopLock, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../../store/user-store';
import { apiCall } from '../../../common-lib/custom-axios';

import ClientBasicInfo from './salesclients/clientbasicinfo';
import ClientDetailsInfo from './salesclients/clientdetailsinfo';

import { salesclients_columns } from '../../tableDatas/salesclientsDatas';

function SalesClients() {

    const { Text } = Typography;
    const { Search } = Input;
    const { TabPane } = Tabs;

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    const [ clientsData, setClientsData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ currentRecord, setCurrentRecord ]= useState({});
    const [ radioValue, setRadioValue ] = useState('전체');
    const [ summary, setSummary ] = useState({});

    useEffect(() => {
        getSummary();
        getClientsData(1);
    }, [])

    const getSummary = async () => {

        const res = await apiCall.get('/summary/salesclients').then(res => res.data);
        //console.log(res)
        setSummary(res.data);

    }

    const getClientsData = async (type) => {
        setLoading(true);
        const res = await apiCall.get(`/salesclients/${type}`).then(res => res.data);

        setClientsData(res.data)
        setLoading(false);
    }

    const TitleHead = (
        <>
        <Row justify='center' align='middle'><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} />&nbsp;<div>매출거래처 전체현황</div></Row>
        </>
    );

    const onSearch = async (value) => {
        setLoading(true);
        const res = await apiCall.get(`/salesclients/1`, { params : {search : value}}).then(res => res.data);
        console.log(res)
        setClientsData(res.data);
        setLoading(false);
    }

    const handleOnRow = (record) => {
        setShowDrawer(true);
        setCurrentRecord(record);
    }

    const drawerCloseHandler = () => {
        setShowDrawer(false)
    }

    const handleTabsChange = () => {

    }

    const handleRadioChange = (e) => {

        if(e.target.value === '전체') { getClientsData(1); setRadioValue('전체') }
        if(e.target.value === '거래 중') { getClientsData(2); setRadioValue('거래 중') }
        if(e.target.value === '폐업') { getClientsData(3); setRadioValue('폐업') }
        if(e.target.value === '거래중지') { getClientsData(4); setRadioValue('거래중지') }
        
    }

    const PageHeaderTitle = currentRecord.closedDate !== null ? (
        <>
            <FontAwesomeIcon icon={faStoreSlash} style={{color:'#ff7875'}} />&nbsp;&nbsp; {currentRecord.shopName}
        </>
    ) : (
        <>
            <FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} />&nbsp;&nbsp; {currentRecord.shopName}
        </>
    )

    return isLogin ? (
        <>
        <div className='SalesClients'>
                <Row gutter={24}>
                    <Col span={4}>
                        <Statistic title="현재 거래 중인 거래처" valueStyle={{ color: '#40a9ff'}} value={summary.tradingNow}
                               prefix={<><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> &nbsp;&nbsp;</>}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="지난달 신규사업자 수" valueStyle={{ color: '#40a9ff'}} value={summary.lastMonth}
                               prefix={<><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> &nbsp;&nbsp;</>}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="이번달 신규사업자 수" valueStyle={{ color: '#40a9ff'}} value={summary.thisMonth}
                               prefix={<><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> &nbsp;&nbsp;</>}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="지난달 폐업 / 중지 사업자 수" valueStyle={{ color: '#40a9ff'}} value={summary.lastMonthClosed}
                               prefix={<><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> &nbsp;&nbsp;</>}/>
                    </Col>
                    <Col span={4}>
                        <Statistic title="현재까지 폐업사업자 수" valueStyle={{ color: '#ff7875'}} value={summary.closed} 
                               prefix={<><FontAwesomeIcon icon={faStoreSlash} style={{color:'#ff7875'}} /> &nbsp;&nbsp;</>} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="현재까지 거래중지 사업자 수" valueStyle={{ color: '#ff7875'}} value={summary.stop} 
                               prefix={<><FontAwesomeIcon icon={faShopLock} style={{color:'#ff7875'}} /> &nbsp;&nbsp;</>} />
                    </Col>
                </Row>
                <Divider />

            <PageHeader className="site-page-header" title={TitleHead} subTitle="전체 매출거래처 관리" 
                        extra={[
                            <Radio.Group value={radioValue} onChange={handleRadioChange} buttonStyle="solid">
                                <Radio.Button value="전체">전체</Radio.Button>
                                <Radio.Button value="거래 중">거래 중</Radio.Button>
                                <Radio.Button value="폐업">폐업</Radio.Button>
                                <Radio.Button value="거래중지">거래중지</Radio.Button>
                            </Radio.Group>,
                            <Search allowClear placeholder="거래처명 혹은 대표자, 주소 중 일부를 입력하세요." enterButton="검색" size="middle" onSearch={onSearch} style={{width:400}} />
                            // <Button key="1" type="primary" icon={<InsertRowBelowOutlined style={{fontSize:'16pt'}} />} onClick={addShipping} style={{borderRadius:20}}>매입배송 등록</Button>,
                        ]} />
            <Table dataSource={clientsData} columns={salesclients_columns} size="small" className='intraTable' loading={loading} 
                   showSorterTooltip={{ title: '오름차순, 내림차순으로 정렬가능' }}
                   bordered={true} pagination={{ showSizeChanger: false, pageSize: 13 }} 
                   onRow={(record) => { return {
                       onClick: () => {handleOnRow(record)},
                   }; }}
            />
        </div>

        <Drawer className="mycustom" title='거래처 정보 상세보기' visible={showDrawer} onClose={drawerCloseHandler} placement='right'  width={1200} >
            { 
                currentRecord.closedDate !== null ?   // 거래중, 폐업을 다르게 표시
                    <>
                        <PageHeader className="site-page-header" title={PageHeaderTitle} subTitle={<Text style={{color:'#ff7875'}} >폐업된 업소입니다.</Text>} />
                    </>
                    :
                    <>
                        <PageHeader className="site-page-header" title={PageHeaderTitle} subTitle={<Text style={{color:'#40a9ff'}} >거래 중인 업소입니다.</Text>} />
                    </>
            } 
            <Tabs tabPosition='top' onChange={handleTabsChange}>
                <TabPane tab="기본정보" key="1">
                    <ClientBasicInfo {...currentRecord} />
                </TabPane>
                <TabPane tab="세부정보" key="2">
                    <ClientDetailsInfo {...currentRecord} />
                </TabPane>
                <TabPane tab="계약 관련정보 내역" key="3">
                    {/* <ContractDocuments {...currentRecord} />  */}
                </TabPane>
                <TabPane tab="냉장고 및 무상임차 장비 내역" key="4">
                    {/* <Refrigerators {...currentRecord} /> */}
                </TabPane>
                <TabPane tab="컴플레인 내역" key="5">
                    {/* <Complains {...currentRecord} /> */}
                </TabPane>
                <TabPane tab="판촉물 공급내역" key="6">
                    {/* <PromotionalMaterial {...currentRecord} /> */}
                </TabPane>
            </Tabs>
            <Divider />
            <Row justify='center'>
              <Button onClick={drawerCloseHandler} style={{borderRadius:20}} >돌아가기</Button>
            </Row>

        </Drawer>
        </>
    ) : (<>
        {() => {sessionStorage.clear(); 
          openErrorModal('인증 시간 만료!', '인증 시간이 만료되어 로그인 화면으로 이동합니다.'); 
          navigate('/'); 
        }}
    </>);
}

export default SalesClients;