import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import { apiCall } from '../../common-lib/custom-axios';


function AuthCheck(props) {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ userId,
             userName,
             admin,                   // 관리자, 비관리자 (관리자 사이트 접속유무 등)
             userGroupId,             // 그룹 아이디 넘버
             department,              // 소속부서
             postPosition,            // 직위
             onesDuty,                // 직책
             territory,               // 담당구역
             birthday,                // 생일
             employeeDate,            // 입사일
             mobile,                  // 핸드폰
             gender,                  // 남성, 여성
             shippingRight,           // 배송을 할 수 있는 담당자 유무
             stamp,                  // 도장 이미지명                       // 두개는 없애도 될듯함. 특정 폴더에 아이디 이름으로 된 이미지 파일이 없으면 이미지 없는 걸로
             avatar,                  // 아바타 이미지명 (아이디)
            }, setUserState] = useRecoilState(userState);
    
    const SSID = sessionStorage.getItem('SSID');

    let navigate = useNavigate();

    useEffect(() => {

        resetLoginInfo();

    }, [])

    const resetLoginInfo = async () => {

        if(SSID && !isSession) {
            const request = await apiCall.get('/auth').then( res => res.data );

            console.log('auth', request);
            
            if(request.loginSuccess) {
                // recoil 상태변수에 상태값들을 저장한다.
                setLoginState({isLogin : request.loginSuccess, isAdmin: request.isAdmin, isSession : SSID});
                setUserState({
                    userId : request.userId,
                    userName : request.userName,
                    admin : request.admin,
                    userGroupId : request.userGroupId,
                    department : request.department,
                    postPosition : request.postPosition,
                    onesDuty : request.onesDuty,
                    territory : request.territory,
                    birthday : request.birthday,
                    employeeDate : request.employeeDate,
                    mobile : request.mobile,
                    gender : request.gender,
                    shippingRight : request.shippingRight,
                    stamp : request.stamp,
                    avatar : request.avatar,
                    });
            }
            else {
                Modal.error({
                    title: '접속시간 만료!',
                    content: '접속시간이 만료되어 로그인 페이지로 이동합니다.', 
                    onOk() { 
                        sessionStorage.clear();
                        navigate('/login');
                    },
                });
            }
    
            // console.log(request)
            // console.log(request.userId)
            // console.log(request.userArea)
        }
        else {
            if(props.isLogin !== isLogin || !isSession)
                navigate('/login');
        }

        console.log('isLogin :', isLogin);
    }

    if(props.isLogin === isLogin)
        return <>{props.children}</>;
        // return React.cloneElement(props.children, {...props});
    else 
        return null;
}
export default AuthCheck;