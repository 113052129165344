import { useEffect, useState } from 'react';
import { Typography, Tabs, PageHeader, Table, Row, Col, Button, Drawer, Modal, Upload, Divider, Space, Image, Empty } from 'antd';
import { AppstoreFilled, InsertRowBelowOutlined, CarFilled, SaveOutlined, PlusOutlined, FileTextOutlined } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../../store/user-store';

import { useForm, Controller, set } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/ko';

import { apiCall, imageApiCall } from '../../../common-lib/custom-axios';
import { openErrorModal, openNotification } from '../../../common-lib/common';

import CarInfo from './cars/carinfo';
import RefuelInfo from './cars/refuelinfo';

import { cars_columns, cars_refueling_columns, cars_repair_columns, cars_insurance_columns, cars_periodicinspection_columns } from '../../tableDatas/carsDatas';
import './../../../css/input.css';


const { Text } = Typography;

function Cars() {

    const { TabPane } = Tabs;

    const { register, setValue, getValues, handleSubmit, reset, control, formState: {errors} } = useForm();

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);


    const [ carsData, setData ] = useState([]);
    const [ carsDetailsData, setData2 ] = useState([]);
    const [ mode, setMode ] = useState('운행 중');
    const [ mode2, setMode2 ] = useState('차량 상세정보');
    const [ tabkey, setTabkey ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ drawerTitle, setDrawerTitle ] = useState('배송 정보 보기');
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerSize, setDrawerSize ] = useState(700);
    const [ drawerMode, setDrawerMode ] = useState('view');
    const [ fileList, setFileList ] = useState([]);
//    const [ viewFileList, setViewFileList ] = useState([]);
    const [ previewVisible, setPreviewVisible ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');

    const locale = { emptyText : <><Empty /><br /></> };

    useEffect(() => {
        getCars(1);
    }, [])

    useEffect(() => {

      if(tabkey === 2) {
        getCarDetails(1, currentRecord.id)
      }
      if(tabkey === 3) {
        getCarDetails(2, currentRecord.id)
      }
      if(tabkey === 4) {
        getCarDetails(3, currentRecord.id)
      }
      if(tabkey === 5) {
        getCarDetails(4, currentRecord.id)
      }

    }, [currentRecord])

    const getCars = async (value) => {

        const res = await apiCall.get('/cars', { params : {'mode' : value}}).then(res => res.data);
        console.log('cars Data', res);

        if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
//          openNotification(mode + ' 목록', res.msg.long);
          setData(res.data);
        }
        else {
          openErrorModal(mode + ' 목록 불러오기 실패', res.msg.long);
        }
    }

    const getCarDetails = async (value, id) => {
        console.log('value, id', value, id)
        const res = await apiCall.get('/cardetails', {params : { 'mode' : value, 'id' : id, 'sMonth' : moment().format('YYYY-MM') }}).then(res => res.data)

        console.log(res);
        if(res.data !== 'undefined')
            setData2(res.data);
        else 
            setData2([]);
    }

    const checkHandle = (e) => {
        if(e === '1') {
            setMode('운행 중')
            getCars(1)
        }
        else if ( e === '2') {
            setMode('휴지 중')
            getCars(2)
        }
        else if ( e === '3') {
            setMode('폐차/매각')
            getCars(3)
        }

        if(e === '1-1') {
          setMode2('차량 상세정보')
          setTabkey(1)
        }
        else if(e === '1-2') {
          setMode2('차량 주유내역')
          getCarDetails(1, currentRecord.id)
          setTabkey(2)
        }
        else if(e === '1-3') {
          setMode2('차량 수리내역')
          getCarDetails(2, currentRecord.id)
          setTabkey(3)
        }
        else if(e === '1-4') {
          setMode2('차량 보험내역')
          getCarDetails(3, currentRecord.id)
          setTabkey(4)
        }
        else if(e === '1-5') {
          setMode2('차량 정기검사내역')
          getCarDetails(4, currentRecord.id)
          setTabkey(5)
        }
    }

    const TitleHead = (
        <>
        <Row justify='center' align='middle'><AppstoreFilled />&nbsp;<div>{mode}</div></Row>
        </>
    );

    const TitleHead2 = (
      <>
      <Row justify='center' align='middle'><AppstoreFilled />&nbsp;<div><Text style={{color:'#40a9ff'}}>{currentRecord.carNumber} {currentRecord.carName}</Text> {mode2}</div></Row>
      </>
    );

    const addCars = () => {
      setDrawerMode('add')
      setShowDrawer(true)
      setDrawerSize(700)
      setDrawerTitle('관리차량 신규 등록')
    }

    const handleCancel = () => {

    }

    const drawerCancelHandler = () => {
        setShowDrawer(false);
    }

    const drawerCloseHandler = () => {
        setShowDrawer(false);
    }

    const handleOnRow = (record) => {
        setCurrentRecord(record);
        setDrawerMode('view');
        setShowDrawer(true);
        setDrawerSize(1200);
        setDrawerTitle('차량별 관리 상세보기');
    }

    const uploadConfig = {
      beforeUpload(file) {
        file.thumbUri = '';
        return file;
      }
    }

    const uploadButton = (
      <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
      </div>
    ) 

    const handlePreview = async (file) => {

      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    }

    const handleChange = (info) => {

      if(info.file.status === 'done') {
//        console.log(info.fileList)
      }
        setFileList(info.fileList);
//        console.log('info',info)
//        console.log('fileList',fileList);
        //setValue('shippingImg', fileList);
    }

    const onSubmit = (data) => {
      console.log(data)
    }

    const DrawerAddEdit = (
        <>
        <form onSubmit={handleSubmit(onSubmit)} className="mycustom">
            <input {...register('carName', {required : true} )} placeholder="차량명칭   예) 티볼리, 마이티, 봉고3 등" />
            <input {...register('carType', {required : true} )} placeholder="차량분류   예) 화물 / 승용"/>
            <input {...register('carNumber', {required : true} )} placeholder="차량번호"/>
            <input {...register('dayOfPurchase', {required : true} )} placeholder="구매일"/>
            <input {...register('carStatus', {required : true} )} placeholder="차량 현재상태"/>

            차량사진 등록 : <br /><br />
            <Upload {...uploadConfig} action="https://images.eilsin.co.kr/uploadimages"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
            >
              {fileList.length < 5 && uploadButton}
              
            </Upload> 
            <input {...register('imagesList')} hidden />
            <input {...register('shippingMode')} hidden />
        </form>
        <Divider></Divider>
        <Row justify='center'>
        <Space>
            <Button onClick={handleSubmit(onSubmit)} icon={<SaveOutlined />} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>저&nbsp;&nbsp;장</Button>
            <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >취소</Button>
        </Space>
        </Row>
        </>
    )

    const DrawerView = (
        <>
          <Tabs defaultActiveKey={tabkey} tabPosition='left' type='card' onChange={checkHandle}>
            <TabPane tab="차량 상세정보" key="1-1">
              <CarInfo {...currentRecord} title={TitleHead2} />
            </TabPane>
            <TabPane tab="주유내역" key="1-2">
              <RefuelInfo {...currentRecord} locale={locale} title={TitleHead2} 
                          dataSource={carsDetailsData || []} columns={cars_refueling_columns} getCarDetails={getCarDetails} />
            </TabPane>
            <TabPane tab="수리내역" key="1-3">
              <PageHeader className="site-page-header" title={TitleHead2} subTitle="차량별 수리 내역을 관리합니다." />
              <Table dataSource='' /* carsRepairHistory */ columns={cars_repair_columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 20}} />
              <Divider></Divider>
              <Row justify='center'>
                <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:120}}>수리내역 등록</Button>
              </Row>
            </TabPane>
            <TabPane tab="보험정보" key="1-4">
              <PageHeader className="site-page-header" title={TitleHead2} subTitle="차량별 보험정보 내역을 관리합니다." />
              <Table dataSource='' columns={cars_insurance_columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 20}} />
              <Divider></Divider>
              <Row justify='center'>
                <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:120}}>보험정보 등록</Button>
              </Row>
            </TabPane>
            <TabPane tab="정기검사내역" key="1-5">
              <PageHeader className="site-page-header" title={TitleHead2} subTitle="차량별 정기검사 내역을 관리합니다." />
              <Table dataSource='' columns={cars_periodicinspection_columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 20}} />
              <Divider></Divider>
              <Row justify='center'>
                <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:160}}>정기검사내역 등록</Button>
              </Row>
            </TabPane>
            <TabPane tab="운행 내역" key="1-6">
              <PageHeader className="site-page-header" title={TitleHead2} subTitle="차량별 정기검사 내역을 관리합니다." />
              <Table dataSource='' columns={cars_periodicinspection_columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 20}} />
              <Divider></Divider>
              <Row justify='center'>
                <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:160}}>정기검사내역 등록</Button>
              </Row>
            </TabPane>
            <TabPane tab="사고 내역" key="1-7">
              <PageHeader className="site-page-header" title={TitleHead2} subTitle="차량별 정기검사 내역을 관리합니다." />
              <Table dataSource='' columns={cars_periodicinspection_columns} size='small' className='intraTable' 
                     bordered={true} pagination={{ showSizeChanger: false, pageSize: 20}} />
              <Divider></Divider>
              <Row justify='center'>
                <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:160}}>정기검사내역 등록</Button>
              </Row>
            </TabPane>
          </Tabs>
          <Divider></Divider>
          <Row justify='center'>
              <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >돌아가기</Button>
          </Row>
        </>
    )

    return (
        <>
          <Tabs tabPosition='top' onChange={checkHandle}>
            <TabPane tab="운행 중" key="1">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="현재 운행, 운영 중인 차량을 관리합니다." 
                            extra={[
                              <Button key="1" type="primary" icon={<CarFilled style={{fontSize:'16pt'}} />} onClick={addCars} style={{borderRadius:20}}>관리차량 등록</Button>,
                              <Button key="1" type="primary" icon={<FileTextOutlined style={{fontSize:'16pt'}} />} style={{borderRadius:20}}>주유현황 출력</Button>,
                            ]} />
                <Table dataSource={carsData} columns={cars_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }} 
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
            <TabPane tab="휴지 중" key="2">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="휴지 중인 차량을 관리합니다." />
                <Table dataSource={carsData} columns={cars_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
            <TabPane tab="폐차/매각" key="3">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="폐차 되었거나 매각된 이전차량들 목록입니다." />
                <Table dataSource={carsData} columns={cars_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
          </Tabs>

          <Drawer className="mycustom" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                  placement='right'  width={drawerSize} >
            {drawerMode === 'view' ? DrawerView : DrawerAddEdit }
          </Drawer>

          <Modal width={700} visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{ width: '100%' }}
              src={previewImage}
            />
          </Modal>
        </>
    )

}

export default Cars;