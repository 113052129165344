import { useState } from 'react';
import { PageHeader, Row, Col, Button, Space, Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Divider } from '@blueprintjs/core';

import './../../../../css/uploadthumnail200.css';

function CarInfo(props) {

    const [ fileList, setFileList ] = useState([]);

    const uploadButton = (
      <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 15,
            }}
          >
            Upload
          </div>
      </div>
    ) 

    console.log(props)

    const handleChange = (info) => {

        // 여기에서 파일 이동을 시킨다.
        console.log(info)
        setFileList(info.fileList);
    }

    return (
        <div className='thumnail200'>
              <PageHeader className="site-page-header" title={props.title} subTitle="차량별 상세정보를 보여줍니다." />
              <Divider style={{marginTop: 10, marginBottom: 10}} />
              <Row align="middle">
                  <Col span={2}></Col>
                  <Col span={21}><Space>
                  <Upload action="https://images.eilsin.co.kr/uploadimages"
                          listType="picture-card"
                          fileList={fileList}
                          onPreview={(e) => { setCurrentUrl(e.url); setVisible(true)}}
                          onChange={handleChange} >
                      {fileList.length < 4 && uploadButton}
                  </Upload>
                  </Space></Col>
                  <Col span={1}></Col>
              </Row>
              <Divider style={{marginTop: 10, marginBottom: 10}} />
              <Row align="middle">
                <Col span={3} align="right">번 호 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.rowNumber} disabled /></Col><Col span={2}></Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">차 량 명 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.carName} disabled /></Col><Col span={2}></Col>
                <Col span={3} align="right">분 류 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.carType} disabled /></Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">차량번호 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.carNumber} disabled /></Col><Col span={2}></Col>
                <Col span={3} align="right">구 매 일 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.dateOfPurchase} disabled /></Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">연료종류 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.fuelType} disabled /></Col><Col span={2}></Col>
                <Col span={3} align="right">소 유 주 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.owner} disabled /></Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">차량상태 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.carStatus} disabled /></Col><Col span={2}></Col>
                <Col span={3} align="right">운행구역 &nbsp;&nbsp;</Col><Col span={7} ><input value={props.carArea} disabled /></Col>
              </Row>
              <br /><br />
              <Row justify='center'>
                <Space size={34}>
                  <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:120}}>휴지 처리</Button>
                  <Button onClick='' type="primary" danger style={{borderRadius:20, color:'white', width:140}}>폐차/매각 처리</Button>
                </Space>
              </Row>
        </div>
    );
}

export default CarInfo;