import React, { useEffect, useState } from 'react';
import { Row, Typography, PageHeader, Table, Button, Drawer, Card, Col, Descriptions } from 'antd';
import { AlertFilled } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../../store/user-store.js';

import { apiCall } from '../../../common-lib/custom-axios';
import { openErrorModal, openNotification } from '../../../common-lib/common';

//import Dashboard from './dashboard/dashboard';
import { noticesList_columns } from '../../tableDatas/jobDatas';

import '../../../css/intratable.css';

const { Text } = Typography;

function Notices() {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    const [ noticesData, setData] = useState([]);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerMode, setDrawerMode ] = useState('view');
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ drawerTitle, setDrawerTitle ] = useState('공지사항 보기');

    useEffect(() => {
        getNoticesList();
    }, []);

    const getNoticesList = async () => {
        const res = await apiCall('/notices').then( res => res.data );
        //console.log(res);
    
        if(res.isSuccess) {
            openNotification('공지사항 목록', res.msg.long);
            setData(res.data);
        }
        else {
            openErrorModal('공지사항 목록 불러오기 실패', res.msg.long);
        }
    }

    const addNotices = () => {

        console.log('공지사항 추가하자')
    }

    const handleOnRow = (record) => {
        //e.stopPropagation();
//        console.log(record)
        setCurrentRecord(record);
        setDrawerMode('view');
        setShowDrawer(true);
    }
    
    const TitleHead = (
        <>
            <Row justify='center' align='middle'><AlertFilled style={{fontSize:'16pt'}} />&nbsp;<div>공지사항</div></Row>
        </>
    );

    const drawerCancelHandler = () => {
        setShowDrawer(false);
    }

    const drawerCloseHandler = () => {
        setShowDrawer(false);
    }

    const DrawerViewNotice = (
        <>
            <Card style={{borderRadius:10, lineHeight:3}}>

                <Descriptions className="viewDesc"
                    bordered
                    title="공지사항 내용"
                    size='default'
                    extra={<Button onClick={drawerCancelHandler} style={{borderRadius:20}} >돌아가기</Button>}
                >
                    <Descriptions.Item label="글번호" span={4}>{currentRecord.id}</Descriptions.Item>

                    <Descriptions.Item label="작성일" span={4}>{currentRecord.wdate2}</Descriptions.Item>
                    <Descriptions.Item label="공지대상" span={4}>{currentRecord.department}</Descriptions.Item>
                    <Descriptions.Item label="작성자" span={4}>{currentRecord.writer}</Descriptions.Item>
                    <Descriptions.Item label="제목" span={4}>{currentRecord.subject}</Descriptions.Item>
                </Descriptions>                
                <Descriptions layout="vertical" bordered>
                    <Descriptions.Item label="공지내용">
                        <div style={{whiteSpace: 'pre-wrap'}}>{currentRecord.contents}</div>
                    </Descriptions.Item>
                </Descriptions>                

            </Card>
            {/* <Divider></Divider> */}<br/>
            <Row justify='center'>
                <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >돌아가기</Button>
            </Row>
        </>
    );

    return (
        <>
        {isLogin ?
        <div className='Notices' style={{width: 1200, display: 'inline-block'}}>
          <PageHeader className="site-page-header" title={TitleHead} subTitle="읽지 않은 공지사항을 꼭 체크해주세요."
                            extra={[
                                <Button key="1" type="primary" icon={<AlertFilled style={{fontSize:'16pt'}} />} onClick={addNotices} style={{borderRadius:20}}>공지사항 등록</Button>,
                            ]} />
            <Table size="small" className='intraTable'
                   dataSource={noticesData} columns={noticesList_columns} bordered={true} pagination={{ pageSize: 12 }}
                   onRow={(record) => { return {
                   onClick: () => {handleOnRow(record)},
                   }; }}
            />

          <Drawer className="mycustom" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                  placement='right' width={720} >
            {drawerMode === 'view' ? DrawerViewNotice : DrawerAddEditNotice }
          </Drawer>

        </div>
        : null }
        </>
    )
}

export default Notices;