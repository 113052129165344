import React from 'react';
import { Row, Typography } from 'antd';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../store/user-store';

const { Text } = Typography;

function Home() {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    return isLogin ? (
        <>
        <div className='Home'>
          {/* <Row justify="center">
              <img src={`../images/assorted-liquor.jpg`} align="center" valign="middle" vspace="40" width="300"/>
          </Row> */}
          <Row justify="center">
              <img src={`../images/logo-login-basic.png`} align="center" valign="middle" vspace="40" />
          </Row>
          {/* <Row justify='center'>
              <Text>[일신물산 인트라넷 관리자모드] <font color='blue'>{userName}</font>님 사용인증 되었습니다.</Text>
          </Row> */}
        </div>
        </>
    ) : null;
}

export default Home;