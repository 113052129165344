import { atom, selector } from 'recoil';

export const loginState = atom({
    key : 'loginState',
    default : {
        isLogin : false,
        isAdmin : false,
        isSession : false,
    }
})

export const getLoginState = selector({
    key : 'getLoginState',
    get : ({get}) => {
        return get(loginState);
    }
})

export const userState = atom({
    key : 'userState',
    default: {
        userId  : '',
        userName : '',
        admin : '',        
        userGroupId : '',  
        department : '',   
        postPosition : '', 
        onesDuty : '',     
        territory : '',    
        birthday : '',     
        employeeDate : '', 
        mobile : '',       
        gender : '',       
        shippingRight : '',
        stamp : '',       
        avatar : '', 
    }
});

export const getUserState = selector({
   key : 'getUserState',
   get : ({get}) => {
       return get(userState);
   }
});

export const groupState = atom({
    key : 'groupState',
    default: {
        groupName  : '',
        admin : '',        
        userGroupId : '',  
    }
});

export const getGroupState = selector({
    key : 'getGroupState',
    get : ({get}) => {
        return get(groupState);
    }
});
 