import { Typography, Row, Col, Tabs, Divider } from 'antd';

function ClientBasicInfo (props) {

    const { Text } = Typography;
    const { TabPane } = Tabs;

    const clientDetails = (
        <>
            <Row align="middle">
                <Col span={2} align="right">코드 &nbsp;&nbsp;</Col>
                <Col span={4} >
                    <input value={props.code} disabled />
                </Col><Col span={2}></Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">거래처명 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.shopName} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">대표자 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.businessOwner} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">사업자번호 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.businessNumber} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">등록일 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.registrationDate} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">업소분류 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.classification} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">과세분류 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.taxClassification} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">폐업일 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.closedDate} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">전화1 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.tel1} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">전화2 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.tel2} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">메모 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.note} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">이메일 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.email} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">업태 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.businessCondition} disabled />
                </Col>
            </Row>
            <Row align="middle">
            <Col span={2} align="right">업종 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.typeOfBusiness} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">전화3 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.tel3} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">fax &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.fax} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">우편번호 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.zipcode} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">주소 &nbsp;&nbsp;</Col>
                <Col span={13} >
                    <input value={props.shopAddress} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">계약일 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.contractDate} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">면허일자 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.licenseDate} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">면허번호 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.licenseNumber} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">주민등록 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.registrationNumber} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">주류카드No &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.cardNumber} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">예금주 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.accountHolder} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">주민등록 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.registrationNumber} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">주류카드No &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.cardNumber} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">예금주 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.accountHolder} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">은행명 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.bankName} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">계좌번호 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.accountNumber} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">이메일2 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.email2} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">최종거래일 &nbsp;&nbsp;</Col>
                <Col span={5} >
                    <input value={props.lastTradingDay} disabled />
                </Col><Col span={1}></Col>
                <Col span={2} align="right">거주 주소 &nbsp;&nbsp;</Col>
                <Col span={13} >
                    <input value={props.homeAddress} disabled />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={2} align="right">메모2 &nbsp;&nbsp;</Col>
                <Col span={9} >
                    <input value={props.note2} disabled />
                </Col>
            </Row>
        </>
    )

    return (
        <>
            {clientDetails}
        </>
    )

}

export default ClientBasicInfo;