import { useEffect, useState } from 'react';
import { Tabs, Table, PageHeader, Row, Col, Button, Drawer, Space, Divider, Upload, Modal, Image, Statistic, Checkbox, Select, AutoComplete, Typography, Popconfirm, Empty } from 'antd';
import { InsertRowBelowOutlined, SaveOutlined, PlusOutlined, CheckCircleOutlined, 
         CloseCircleOutlined, ArrowRightOutlined, FileImageOutlined, CloseOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faWineBottle, faSackDollar  } from '@fortawesome/free-solid-svg-icons';
         
import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../../store/user-store.js';

import { useForm, Controller, set } from 'react-hook-form';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/ko';

import { apiCall, imageApiCall } from '../../../common-lib/custom-axios';
import { openErrorModal, openNotification } from '../../../common-lib/common';

import { shipping_columns, computerized_columns, payment_columns, completion_columns } from '../../tableDatas/shippingDatas';
import './../../../css/input.css';

import ShippingStatistics from './shipping/shippingstatistics';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
});

const { Text } = Typography;

function Shipping() {

    const { TabPane } = Tabs;

    const { register, setValue, getValues, handleSubmit, reset, control, formState: {errors} } = useForm();
    const { register: register2, setValue: setValue2, control: control2, handleSubmit: handleSubmit2, reset: reset2, formState: {errors: errors2} } = useForm();
    const { register: register3, setValue: setValue3, control: control3, handleSubmit: handleSubmit3, reset: reset3, formState: {errors: errors3} } = useForm();
    const { register: register4, handleSubmit: handleSubmit4, reset: reset4, control: control4, formState: {errors: errors4} } = useForm();
    const { handleSubmit: handleSubmit5 } = useForm();

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    const [ mode, setMode ] = useState('매입배송');
    const [ loading, setLoading ] = useState(false);
    const [ shippingData, setData ] = useState([]);
    const [ currentRecord, setCurrentRecord ] = useState({});
    const [ drawerTitle, setDrawerTitle ] = useState('배송 정보 보기');
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerMode, setDrawerMode ] = useState('view');
    const [ fileList, setFileList ] = useState([]);
//    const [ viewFileList, setViewFileList ] = useState([]);
    const [ previewVisible, setPreviewVisible ] = useState(false);
    const [ previewImage, setPreviewImage ] = useState('');
    const [ previewTitle, setPreviewTitle ] = useState('');
    const [ monthPaySummary, setMonthPaySummary ] = useState({});
    const [ optData, setOptData ] = useState([]);

    const today = moment();

    const locale = { emptyText : <><Empty /><br />불러올 데이터가 없습니다.</> };

    useEffect(() => {
        getCarsList();
        getMembersList();
        getShippingData(1);

        getOptionList();

        getMonthPaySummary();
    }, [])

    useEffect(() => {
      setCurrentRecord(currentRecord)
    }, [currentRecord])

    // useEffect(() => {
    //     if(mode === '매입배송') getShippingData(1);
    //     if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
    //     if(mode === '전산등록 대기') getShippingData(3);
    //     if(mode === '결제대기') getShippingData(4);
    //     if(mode === '매입/반납 처리 완료') getShippingData(5);
    // }, [mode]);

    const [ cars, setCars ] = useState([]);
    const [ members, setMembers ] = useState([]);

    const getCarsList = async () => {
        const res = await apiCall.get('/carlist').then(res => res.data);
        setCars(res.data);
    }

    const getMembersList = async () => {
        const res = await apiCall.get('/userlist').then(res => res.data);
        setMembers(res.data);
    } 

    const getMonthPaySummary = async () => {
        const res = await apiCall.get('/shipping/monthpaysummary').then(res => res.data);
//        console.log(res);
        setMonthPaySummary(res.data);
    }

    const getOptionList = async () => {
        const res = await apiCall.get('/shipping/buyclients').then(res => res.data );
        setOptData(res.data);
        //console.log(res);
    }

    const TitleHead = (
        <>
        <Row justify='center' align='middle'><FontAwesomeIcon icon={faTruckFast} style={{color:'#40a9ff'}} />&nbsp;<div>{mode}</div></Row>
        </>
    );

    const editCora = async (record) => {

      let data = {};
      data.cora = 'Y';
      const res = await apiCall.put(`/shipping/${record.id}`, {data}).then(res => res.data);
    
        getShippingData(2);
    }

    const shipping_cora_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 60,
        align: 'center',
        key: 'id',
    },
    {
        title: '등록자',
        dataIndex: 'registant',
        width: 80,
        align: 'center',
        key: 'registant',
    },
    {
        title: '배송일자',
        dataIndex: 'ddate',
        width: 80,
        align: 'center',
        key: 'ddate',
    },
    {
        title: '코라등록',
        dataIndex: 'cora',
        width: 70,
        align: 'center',
        key: 'cora',
        render: (text, record) => (
            record.cora === 'Y' ? <><CheckCircleOutlined style={{color:'#40a9ff', fontSize: '18px'}} /></> : 
            <><Popconfirm title="코라등록을 완료하셨나요 ?"
                          onConfirm={(e) => {e.stopPropagation(); editCora(record); }}
                          onCancel={(e) => e.stopPropagation()}
                          placement="topRight"
                          okText="예" cancelText="아니오">
                            <a onClick={e => e.stopPropagation()}><CloseCircleOutlined style={{color:'#ff7875', fontSize: '18px'}} /></a>
               </Popconfirm></>
        )
    },
    {
        title: '배송물',
        dataIndex: 'goodsType',
        width: 70,
        align: 'center',
        key: 'goodsType',
        render : (text, record) => {
            let fontColor = '#40a9ff';

            if(record.goodsType === '상품')
                fontColor = '#ff7875';

            return (
            <>
                <div><Text style={{color:`${fontColor}` }}>{text}</Text></div>
            </>
            )
        }
    },
    {
        title: '배송지',
        dataIndex: 'shippingAddress',
        width: 200,
        align: 'center',
        key: 'shippingAddress',
        render: (_, record) => (
                <><Text>{record.startingPoint} <ArrowRightOutlined style={{color:'#40a9ff'}} /> {record.destination}</Text></>
        ),
    },
    {
        title: '배송형태',
        dataIndex: 'shippingType',
        width: 80,
        align: 'center',
        key: 'shippingType',
    },
    {
        title: '배송자',
        dataIndex: 'shipper',
        width: 80,
        align: 'center',
        key: 'shipper',
    },
    {
        title: '배송내용',
        dataIndex: 'contents',
        width: 360,
        align: 'center',
        key: 'contents',
    },
    {
        title: '배송사진',
        dataIndex: 'shippingImg',
        width: 80,
        align: 'center',
        key: 'shippingImg',
        render: (_, record) => (
            record.shippingImg ? <><FileImageOutlined style={{color:'#40a9ff', fontSize: '18px'}} /></> : <><CloseOutlined style={{color:'#ff7875', fontSize: '18px'}} /></>
        )
    },
];

    const getShippingData = async (value, chkValues, selValue) => {
        setLoading(true)
        const res = await apiCall.get('/shipping', { params : {'mode' : value, 'chk' : chkValues, 'sel' : selValue }}).then(res => res.data);
//        console.log(res);

        if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
          // openNotification(mode + ' 목록', res.msg.long);
            setData(res.data);
        }
        else {
            openErrorModal(mode + ' 목록 불러오기 실패', res.msg.long);
        }
        setLoading(false)
    }

    const checkHandle = (e) => {
        setLoading(true)
        if(e === '1') {
          setMode('매입배송')
          getShippingData(1);
        }
        else if(e === '2') {
          setMode('공병반납 / 코라등록 대기')
          getShippingData(2);
        }
        else if(e === '3') {
          setMode('전산등록 대기')
          getShippingData(3);
        }
        else if(e === '4') {
          setMode('결제대기')
          getMonthPaySummary();
          getShippingData(4);
        }
        else if(e === '5') {
          setMode('매입/반납 처리 완료')
          getShippingData(5);
        }
        else if(e === '6') {
          setMode('매입 통계자료')
        }
        setLoading(false)
    }

    const drawerCancelHandler = () => {
      setShowDrawer(false);
      setFileList([]);
      setCurrentRecord({});
    }

    const drawerCloseHandler = () => {
      setShowDrawer(false);
      setFileList([]);
      setCurrentRecord({});
    }

    const handleOnRow = (record) => {
      //e.stopPropagation();
  //        console.log(record)
        setCurrentRecord(record);
        setDrawerMode('view');
        setShowDrawer(true);
        setDrawerTitle('매입배송 개별확인');

        console.log(record)
        reset2({
          dateOfIssue : '',
          totalAmount : '',
        })

        reset3({
          statementName : record.statementName,
          dateOfIssue : record.dateOfIssue,
          totalAmount : record.totalAmount,
        })

        reset4({
          paymentDate : today.format('YYYY-MM-DD'),
          paymentChecker : userName,
          payment : 'Y',
        })
    }
  
    const addShipping = () => {
      setDrawerMode('add');
      setCurrentRecord({});

      if(mode === '매입배송') {
        setDrawerTitle('매입배송 등록');
        reset({
          shippingMode : mode,
          registant : userName,
          goodsType : '상품',
          ddate : today.format('YYYY-MM-DD'),
          startingPoint : '',
          destination : '(유)일신물산',
          shipper : '',
          shippingType : '',
          shippingCar : '',
          contents : '',
          shippingImg : '',
        })
      }

      if(mode === '공병반납 / 코라등록 대기') {
        setDrawerTitle('공병반납 등록');
        reset({
          shippingMode : mode,
          registant : userName,
          goodsType : '공병',
          ddate : today.format('YYYY-MM-DD'),
          startingPoint : '(유)일신물산',
          destination : '',
          shipper : '',
          shippingType : '',
          shippingCar : '',
          contents : '',
          shippingImg : '',
        })
      }

      //form.resetFields();
      setShowDrawer(true);
    }

    const onSubmit = async data => {
      setLoading(true);

      const res = await apiCall.post('/shipping', {data}).then(res => res.data);
      const res2 = await imageApiCall.post('/images/shipping', {data}).then(res => res.data);

//      console.log(res);
//      console.log(res2);

      if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
        setShowDrawer(false);
        setFileList([]);
        openNotification(mode + ' 목록 저장 성공', res.msg.long);

        if(mode === '매입배송') getShippingData(1);
        if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
        if(mode === '전산등록 대기') getShippingData(3);
        if(mode === '결제대기') getShippingData(4);
        if(mode === '매입/반납 처리 완료') getShippingData(5);
      }
      else {
        openErrorModal(mode + ' 목록 저장하기 실패', res.msg.long);
      }
      setLoading(false);
    }

    // const onSubmit = async (values) => {

    //   // console.log('Submit', values)

    //     const res = await apiCall.post('/shipping').then(res => res.data);

    //     if(res.isSuccess) {
    //         openNotification('상품배송 등록 성공', res.msg.long);
    //         //setUsersData(res.data);
    //     }
    //     else {
    //         openErrorModal('상품배송 등록 실패', res.msg.long);
    //     }
    // }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
  
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    }

    useEffect(() => {
      setValue('imagesList', fileList);
    }, [fileList])

    // const handleChange = ({ fileList: newFileList }) => {
    const handleChange = (info) => {

      if(info.file.status === 'done') {
//        console.log(info.fileList)
      }
        setFileList(info.fileList);
//        console.log('info',info)
//        console.log('fileList',fileList);
        //setValue('shippingImg', fileList);
    }

    const handleCancel = () => setPreviewVisible(false);

    const uploadButton = (
      <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
      </div>
    ) 

    // const resizeFile = (file) =>
    //     new Promise((resolve) => {
    //       Resizer.imageFileResizer(
    //         file,
    //         600,
    //         600,
    //         "JPEG",
    //         100,
    //         0,
    //         (uri) => {
    //           resolve(uri);
    //         },
    //         "base64"
    //       );
    // });

    const uploadConfig = {
        beforeUpload(file) {
          file.thumbUri = '';
          return file;
        }
    }

    const DrawerAddEdit = (
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="mycustom">
            <input {...register('registant')} disabled />
            <input {...register('goodsType')} disabled />
            { mode === "매입배송" && 
              <>
              <Row justify='space-between'>
                <div>
                  {errors.startingPoint && <div style={{color:'#ff7875'}}>매입 제조사를 선택해주세요.</div>}
                  <select {...register('startingPoint', { required: true })} className="optionColor" style={{width:300}}>
                      <option value="" disabled selected>매입 제조사</option>
                      <option value="한라산">한라산</option>
                      <option value="하이트 진로">하이트 진로</option>
                      <option value="OB맥주">OB맥주</option>
                      <option value="골든블루">골든블루</option>
                      <option value="롯데">롯데</option>
                      <option value="보해">보해</option>
                      <option value="해당 제조사">해당 제조사</option>
                  </select>
                </div>
                <div>
                  <input {...register('destination')} disabled style={{width:300}} />
                </div>
              </Row>
              </>
            }
            { mode === "공병반납 / 코라등록 대기" && 
              <>
              <Row justify='space-between'>
                <div>
                  <input {...register('startingPoint')} disabled style={{width:300}} />
                </div>
                <div>
                  {errors.destination && <div style={{color:'#ff7875'}}>반납 제조사를 선택해주세요.</div>}
                  <select {...register('destination', { required: true })} className="optionColor" style={{width:300}}>
                      <option value="" disabled selected>반납 제조사</option>
                      <option value="한라산">한라산</option>
                      <option value="하이트 진로">하이트 진로</option>
                      <option value="OB맥주">OB맥주</option>
                      <option value="롯데">롯데</option>
                      <option value="보해">보해</option>
                      <option value="해당 제조사">해당 제조사</option>
                  </select>
                </div>
              </Row>
              </>
            }
            
            {errors.ddate && <div style={{color:'#ff7875'}}>반납일자를 입력해주세요.</div>}
            <Controller control={control} name='ddate' rules={{ required: true }} render={({ field: { onChange, onBlur, value, ref}}) => (
                <InputMask mask='9999-99-99' onChange={onChange} placeholder="배송일자 YYYY-MM-DD"  value={value} />
            )} />
            <Row justify='space-between'>
                <div>
                {errors.shipper && <div style={{color:'#ff7875'}}>배송한 사람을 선택해주세요.</div>}
                <select {...register('shipper', { required: true })} style={{width:200}} className="optionColor">
                    <option value="" disabled selected>배송자</option>
                    {
                      members.map(member => ( <option value={member.userName} className="optionColor" key={member.id} >{member.userName}</option>))
                    }
                    <option value="해당 제조사">해당 제조사</option>
                </select>
                </div>
                <div>
                {errors.shippingType && <div style={{color:'#ff7875'}}>자차/용차를 선택하세요.</div>}
                <select {...register('shippingType', { required: true })} style={{width:200}} className="optionColor">
                    <option value="" disabled selected>배송형태</option>
                    <option value="자차" class="optionColor">자차</option>
                    <option value="용차" class="optionColor">용차</option>
                </select>
                </div>
                <div>
                {errors.shippingCar && <div style={{color:'#ff7875'}}>배송에 사용된 차를 선택하세요.</div>}
                <select {...register('shippingCar', { required: true })} style={{width:200}} className="optionColor">
                  <option value="" disabled selected>배송차량</option>
                  {
                    cars.map(car => ( <option value={car.carNumber} className="optionColor" key={car.id} >{car.carNumber} {car.carName}</option>))
                  }
                  <option value="제조사차량">제조사 차량</option>
                </select>
                </div>
            </Row>
            {errors.contents && <div style={{color:'#ff7875'}}>반납물품 내역을 입력해주세요.</div>}
            <textarea {...register('contents', { required: true })} rows="8" placeholder="배송 상품 및 공용기 목록"></textarea>

            <Upload {...uploadConfig} action="https://images.eilsin.co.kr/uploadimages"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
            >
              {fileList.length < 5 && uploadButton}
              
            </Upload> 
            <input {...register('imagesList')} hidden />
            <input {...register('shippingMode')} hidden />
        </form>
        <Divider></Divider>
        <Row justify='center'>
        <Space>
            <Button onClick={handleSubmit(onSubmit)} icon={<SaveOutlined />} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>저&nbsp;&nbsp;장</Button>
            <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >취소</Button>
        </Space>
        </Row>

      </div>
    );

    const onSubmit2 = async (data) => {
      setLoading(true);
//      console.log(data);
      const res = await apiCall.put(`/shipping/${currentRecord.id}`, {data}).then(res => res.data);
      setLoading(false);

//      console.log(res);

      if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
        setShowDrawer(false);
        setFileList([]);
        openNotification(mode + ' 목록 전표확인 -> 전산등록 대기 전환 성공', res.msg.long);

        if(mode === '매입배송') getShippingData(1);
        if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
        if(mode === '전산등록 대기') getShippingData(3);
        if(mode === '결제대기') getShippingData(4);
        if(mode === '매입/반납 처리 완료') getShippingData(5);
      }
      else {
        openErrorModal(mode + ' 목록 전표확인 실패', res.msg.long);
      }
    }

    const onSubmit3 = async (data) => {

      setLoading(true);
      const res = await apiCall.put(`/shipping/${currentRecord.id}`, {data}).then(res => res.data);

      if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
        setShowDrawer(false);
        openNotification(mode + ' 전산등록 -> 결제대기 이관 성공', res.msg.long);

        if(mode === '매입배송') getShippingData(1);
        if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
        if(mode === '전산등록 대기') getShippingData(3);
        if(mode === '결제대기') getShippingData(4);
        if(mode === '매입/반납 처리 완료') getShippingData(5);
        setLoading(false);
      }
      else {
        openErrorModal(mode + ' 목록 전표확인 실패', res.msg.long);
        setLoading(false);
      }
    }

    const onSubmit4 = async (data) => {

//      console.log(data)
//      console.log(currentRecord.id)
      setLoading(true);

      const res = await apiCall.put(`/shipping/${currentRecord.id}`, {data}).then(res => res.data);

      console.log(res)

      if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
        setShowDrawer(false);
        openNotification(mode + ' 결제완료 성공', res.msg.long);

        getMonthPaySummary();
        setLoading(false);

        if(mode === '매입배송') getShippingData(1);
        if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
        if(mode === '전산등록 대기') getShippingData(3);
        if(mode === '결제대기') getShippingData(4);
        if(mode === '매입/반납 처리 완료') getShippingData(5);
      }
      else {
        openErrorModal(mode + ' 결제완료 실패', res.msg.long);
        setLoading(false);
      }
    }

    const onSubmitDel = async () => {

      setLoading(true);
      const sepDate = currentRecord.ddate.split('-');
      // , { data : { imgList : currentRecord.shippingImg }} /${sepDate[0]}
      const res2 = await imageApiCall.delete('/images/shipping', { data : { 'imgList': currentRecord.shippingImg, 'year':sepDate[0]}}).then(res => res.data);
      //console.log(res2)

      const res = await apiCall.delete(`/shipping/${currentRecord.id}`).then(res => res.data);
      //console.log(res);

      if(res.isSuccess && typeof(res.isSuccess) !== 'undefined') {
        setShowDrawer(false);
        openNotification(mode + ' 배송삭제 성공', res.msg.long);

        getMonthPaySummary();

        if(mode === '매입배송') getShippingData(1);
        if(mode === '공병반납 / 코라등록 대기') getShippingData(2);
        if(mode === '전산등록 대기') getShippingData(3);
        if(mode === '결제대기') getShippingData(4);
        if(mode === '매입/반납 처리 완료') getShippingData(5);
      }
      else {
        openErrorModal(mode + ' 배송삭제 실패', res.msg.long);
      }
      setLoading(false);
    }

    const HandleOptChange = (value) => {
        setValue2('statementName', value);
    }

    const HandleOptChange2 = (value) => {
        setValue3('statementName', value);
    }

    const DrawerView = (
      <>
        <div className='mycustom' style={{marginLeft: 30}}>
          <div>
            <Row align="middle">
              <Col span={3} align="right">번 호 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.id} disabled /></Col><Col span={2}></Col>
            </Row>
            <Row align="middle">
              <Col span={3} align="right">등 록 자 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.registant} disabled /></Col><Col span={2}></Col>
              <Col span={3} align="right">배 송 일 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.ddate2} disabled /></Col>
            </Row>
            <Row align="middle">
              <Col span={3} align="right">배 송 물 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.goodsType} disabled /></Col><Col span={2}></Col>
              <Col span={3} align="right">배송차량 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.shippingCar} disabled /></Col>
            </Row>
            <Row align="middle">
              <Col span={3} align="right">출 발 지 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.startingPoint} disabled /></Col><Col span={2}></Col>
              <Col span={3} align="right">도 착 지 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.destination} disabled /></Col>
            </Row>
            <Row align="middle">
              <Col span={3} align="right">배송형태 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.shippingType} disabled /></Col><Col span={2}></Col>
              <Col span={3} align="right">배 송 자 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.shipper} disabled /></Col>
            </Row>
            <Row align="middle">
              <Col span={3} align="right">배송내용 &nbsp;&nbsp;</Col><Col span={19} >
                <textarea name="contents" disabled style={{color:'#40a9ff'}}
                          value={currentRecord.contents} onChange={(e) => e.target.value} ></textarea></Col>
            </Row>
            <Row align="middle">
                <Image.PreviewGroup>
                  <Space size={12}>
              {
                typeof(currentRecord.filenames) !== 'undefined' &&
                  currentRecord.filenames.map(filename => { 
                    let folder = currentRecord.ddate.split('-');
                    let fileUrl = 'https://images.eilsin.co.kr/shipping/'+ folder[0] +'/' + filename;
                    return ( <Image width={100} src={fileUrl} /> ) 
                  })
              }
                  </Space>
                </Image.PreviewGroup>
            </Row>
          <Divider></Divider>
          { (mode === "매입배송" || mode === "공병반납 / 코라등록 대기") && (
            <form onSubmit={handleSubmit2(onSubmit2)}>
              <Row align="middle">
                <Col span={4} align="right"><Text type='danger'>* 전표일자</Text> &nbsp;&nbsp;</Col>
                <Col span={8} >
                  {errors2.dateOfIssue && <div style={{color:'#ff7875'}}>전표 발행일자를 입력하세요</div>}
                  <Controller control={control2} name='dateOfIssue' rules={{ required: true }} render={({ field: { onChange, onBlur, value, ref}}) => (
                      <InputMask mask='9999-99-99' onChange={onChange} placeholder="전표일자 YYYY-MM-DD" value={value} />
                  )} />
                </Col>
              </Row>
              <Row align="middle">
                <Col span={4} align="right"><Text type='danger'>* 전표발행처</Text> &nbsp;&nbsp;</Col>
                <Col span={11} >
                  <div>{currentRecord.statementName}</div>
                  {errors2.statementName && <div style={{color:'#ff7875'}}>전표발행 회사를 입력해주세요.</div>}
                  <AutoComplete options={optData} style={{width: 250}} onChange={HandleOptChange} defaultValue={currentRecord.statementName}
                                placeholder="전표발행회사 선택" filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}/>
                  <input {...register2('statementName', { required: true })}  style={{width:250}} hidden />
                </Col>
              </Row>
              <Row align="middle">
                <Col span={4} align="right"><Text type='danger'>* 전표금액</Text> &nbsp;&nbsp;</Col>
                <Col span={11} >
                  {errors2.totalAmount && <div style={{color:'#ff7875'}}>전표의 합계 금액을 입력해주세요.</div>}
                  <input {...register2('totalAmount', { required: true })}  style={{width:250}} /> 원
                  <input {...register2('statementChecker')} value={userName} hidden />
                  <input {...register2('statementDate')} value={today.format('YYYY-MM-DD')} hidden />
                </Col>
                <Col span={4}><Button onClick={handleSubmit2(onSubmit2)} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>전표확인</Button></Col>
              </Row>
            </form>
            )}
          { mode === "전산등록 대기" && (
            <>
            <form onSubmit={handleSubmit3(onSubmit3)} className="mycustom">
            <Row align="middle">
              <Col span={4} align="right">전표확인 &nbsp;&nbsp;</Col><Col span={7} ><input value={currentRecord.statementChecker} disabled /></Col><Col span={2}></Col>
            </Row>
            <Row align="middle">
              <Col span={4} align="right"><Text type='danger'>* 전표발행처</Text> &nbsp;&nbsp;</Col>
                <Col span={10} >
                  {errors3.statementName && <div style={{color:'#ff7875'}}>전표발행 회사를 입력해주세요.</div>}
                  <AutoComplete options={optData} style={{width: 250}} onChange={HandleOptChange2} defaultValue={currentRecord.statementName}
                                placeholder="전표발행회사 선택" filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}/>
                </Col>
                <Col span={9}>
                  <input {...register3('statementName', { required: true })}  style={{width:250}} disabled />
                </Col>
            </Row>
            <Row align="middle">
              <Col span={4} align="right"><Text type='danger'>* 전표일자</Text> &nbsp;&nbsp;</Col>
              <Col span={7} >
                  {errors3.dateOfIssue && <div style={{color:'#ff7875'}}>전표 발행일자를 입력하세요</div>}
                  <Controller control={control3} name='dateOfIssue' rules={{ required: true }} render={({ field: { onChange, onBlur, value, ref}}) => (
                      <InputMask mask='9999-99-99' onChange={onChange} placeholder="전표일자 YYYY-MM-DD" value={value} />
                  )} />
              </Col>
              <Col span={4} align="right"><Text type='danger'>* 전표금액</Text> &nbsp;&nbsp;</Col>
              <Col span={7} >
                <input {...register3('totalAmount')} defaultValue={`${currentRecord.totalAmount}`} />
              </Col>
            </Row>
              <input {...register3('computerizedChecker')} value={userName} hidden />
              <input {...register3('computerizedDate')} value={today.format('YYYY-MM-DD')} hidden />
              <br /><br />
              <Row align="middle">
                <Col span={22} align="center"><Button onClick={handleSubmit3(onSubmit3)} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>전산입력 완료</Button></Col>
              </Row>
            </form>
            </>
            )}
          { mode === "결제대기" && (
            <>
              <Row align="middle">
                <Col span={3} align="right">전표확인 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.statementChecker} disabled />
                </Col><Col span={2}></Col>
                <Col span={3} align="right">전표금액 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={`${currentRecord.totalAmount2} 원`} disabled />
                </Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">전산등록 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.computerizedChecker} disabled />
                </Col>
              </Row>
            <form onSubmit={handleSubmit4(onSubmit4)} className="mycustom">
              <Row align="middle">
                <Col span={3} align="right"><Text type='danger'>* 결제일</Text> &nbsp;&nbsp;</Col>
                <Col span={7} >
                  {errors4.paymentdate && <div style={{color:'#ff7875'}}>결제일자를 입력해주세요. YYYY-MM-DD</div>}
                  <Controller control={control4} name='paymentDate' rules={{ required: true }} render={({ field: { onChange, onBlur, value, ref}}) => (
                      <InputMask mask='9999-99-99' onChange={onChange} placeholder="결제일자 YYYY-MM-DD"  defaultValue={today.format('YYYY-MM-DD')} />
                  )} />
                </Col><Col span={2}></Col>
                <Col span={3} align="right">결제확인 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input {...register4('paymentChecker')} disabled />
                  <input {...register4('payment')} hidden />
                  <input {...register4('paymentDate')} value={today.format('YYYY-MM-DD')} hidden />
                </Col>
              </Row>
              <Row justify="center">
                <Col span={4} ><Button onClick={handleSubmit4(onSubmit4)} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>결제확인</Button></Col>
              </Row>
            </form>
            </>
            )}
          { mode === "매입/반납 처리 완료" && (
            <>
              <Row align="middle">
                <Col span={3} align="right">전표확인 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.statementChecker} disabled />
                </Col><Col span={2}></Col>
                <Col span={3} align="right">전표금액 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={`${currentRecord.totalAmount2} 원`} disabled />
                </Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">전산등록 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.computerizedChecker} disabled />
                </Col>
              </Row>
              <Row align="middle">
                <Col span={3} align="right">결제일 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.paymentDate} disabled />
                </Col><Col span={2}></Col>
                <Col span={3} align="right">결제확인 &nbsp;&nbsp;</Col>
                <Col span={7} >
                  <input value={currentRecord.paymentChecker} disabled />
                </Col>
              </Row>
            </>
            )}
          <Divider></Divider>
          <Row justify='center'>
              <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >돌아가기</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              { 
                (mode === "매입배송" || mode === "공병반납 / 코라등록 대기") && 
                <Button onClick={handleSubmit5(onSubmitDel)} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>배송삭제</Button>
              }
          </Row>
          </div>
        </div>
      </>
    );

    const chkOptions = ['상품', '공병'];

    const checkboxOnChange = (checkedValues) => {
        if(mode === '전산등록 대기') getShippingData(3, checkedValues);
        // if(mode === '결제대기') getShippingData(4, checkedValues);
        // if(mode === '매입/반납 처리 완료') getShippingData(5, checkedValues);
    }

    const checkBoxView = (
        <>
          구분 : &nbsp;<Checkbox.Group options={chkOptions} onChange={checkboxOnChange} />
        </>
    );

    const selectOnChange = (value) => {
        if(mode === '결제대기') getShippingData(4, '', value);
    }

    const selectBoxView = (
        <>
          제조사 선택 : &nbsp;<Select style={{width:200}} placeholder="배송지 선택" onChange={selectOnChange}>
            <Option value="전체보기">전체보기</Option>
            <Option value="(유)일신물산">(유)일신물산</Option>
            <Option value="한라산">한라산</Option>
            <Option value="하이트 진로">하이트 진로</Option>
            <Option value="OB맥주">OB맥주</Option>
            <Option value="롯데">롯데</Option>
            <Option value="보해">보해</Option>
            <Option value="해당 제조사">해당 제조사</Option>
          </Select>
        </>
    );

    return isLogin ? (
        <>
          <Tabs tabPosition='top' onChange={checkHandle}>
            <TabPane tab="매입배송" key="1">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="주류매입 / 공용기배송에 대한 자료를 입력 관리합니다." 
                            extra={[
                              <Button key="1" type="primary" icon={<InsertRowBelowOutlined style={{fontSize:'16pt'}} />} onClick={addShipping} style={{borderRadius:20}}>매입배송 등록</Button>,
                            ]} />
                <Table locale={locale} dataSource={shippingData} columns={shipping_columns} size="small" className='intraTable' loading={loading}
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }} 
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
            <TabPane tab="공병반납 / 코라등록 대기" key="2">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="공병의 경우 코라등록에 대기됩니다."
                            extra={[
                              <Button key="1" type="primary" icon={<InsertRowBelowOutlined style={{fontSize:'16pt'}} />} onClick={addShipping} style={{borderRadius:20}}>공병반납 등록</Button>,
                            ]} />
                <Table dataSource={shippingData} columns={shipping_cora_columns} size="small" className='intraTable' loading={loading} 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
            <TabPane tab="전산등록 대기" key="3">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="실제 배송과 매입전표를 확인하고 전표가 맞는 경우 전산등록합니다."
                            extra={checkBoxView} />
                <Table dataSource={shippingData} columns={computerized_columns} size="small" className='intraTable' loading={loading} 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                       }; }}
                />
            </TabPane> 
            <TabPane tab="결제대기" key="4">
                <Row gutter={16}>
                  <Col span={4}>
                    <Statistic title="당월 총 결제 예정액" valueStyle={{ color: '#ff7875'}} value={monthPaySummary.totalPayment} 
                               prefix={<><FontAwesomeIcon icon={faSackDollar} style={{color:'#ff7875'}} /> &nbsp;</>}/>
                  </Col>
                  <Col span={4}>
                    <Statistic title="현재 미결제액" valueStyle={{ color: '#ff7875'}} value={monthPaySummary.outstandingAmount}
                               prefix={<><FontAwesomeIcon icon={faSackDollar} style={{color:'#ff7875'}} /> &nbsp;</>}/>
                  </Col>
                  <Col span={4}>
                    <Statistic title="당월 결제 완료금액" valueStyle={{ color: '#ff7875'}} value={monthPaySummary.payment}
                               prefix={<><FontAwesomeIcon icon={faSackDollar} style={{color:'#ff7875'}} /> &nbsp;</>}/>
                  </Col>
                  <Col span={4}>
                    <Statistic title="공용기 총 예정 환급액" valueStyle={{ color: '#40a9ff'}} value={monthPaySummary.totalRefund}
                               prefix={<><FontAwesomeIcon icon={faWineBottle} style={{color:'#40a9ff'}} /> &nbsp;</>}/>
                  </Col>
                  <Col span={4}>
                    <Statistic title="당월 공용기 미환급액" valueStyle={{ color: '#40a9ff'}} value={monthPaySummary.nonrefundableAmount}
                               prefix={<><FontAwesomeIcon icon={faWineBottle} style={{color:'#40a9ff'}} /> &nbsp;</>}/>
                  </Col>
                  <Col span={4}>
                    <Statistic title="당월 공용기 환급액" valueStyle={{ color: '#40a9ff'}} value={monthPaySummary.refund} 
                               prefix={<><FontAwesomeIcon icon={faWineBottle} style={{color:'#40a9ff'}} /> &nbsp;</>}/>
                  </Col>
                </Row>
                  <PageHeader className="site-page-header" title={TitleHead} subTitle="결제가 되지 않은 매입배송 / 공병결제가 남아있습니다."
                              extra={[
                                ,
                                selectBoxView,
                              ]} />
                  <Table dataSource={shippingData} columns={payment_columns} size="small" className='intraTable' loading={loading} 
                         bordered={true} pagination={{ showSizeChanger: false, pageSize: 10 }}
                         onRow={(record) => { return {
                          onClick: () => {handleOnRow(record)},
                          }; }
                         }
                         summary={ (pageData) => {

                            let tmpAmount = 0;

                            pageData.forEach(({ totalAmount, goodsType }) => {
                                if(goodsType === '상품')
                                  tmpAmount += parseInt(totalAmount);
                                else if(goodsType === '공병')
                                  tmpAmount -= parseInt(totalAmount);
                            });

                            let _tmpAmount = '';
                            _tmpAmount = tmpAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            return (
                              <>
                                <Table.Summary.Row style={{backgroundColor:'#f6f6f6'}}>
                                  <Table.Summary.Cell index={0} colSpan={10} align='right'><div  style={{color:'red', fontSize:'12pt'}}>현재 페이지 결제대기 합계금액 &nbsp;&nbsp;&nbsp;&nbsp;</div></Table.Summary.Cell>
                                  <Table.Summary.Cell index={1} colSpan={2} align='center'><div  style={{color:'red', fontSize:'12pt'}}>{_tmpAmount} 원</div></Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            )

                         }}
                  />
            </TabPane>
            <TabPane tab="매입/반납 처리 완료" key="5">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="모든 작업이 완료된 배송 목록을 관리합니다." />
                <Table dataSource={shippingData} columns={completion_columns} size="small" className='intraTable' loading={loading} 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}
                       onRow={(record) => { return {
                        onClick: () => {handleOnRow(record)},
                        }; }}
                />
            </TabPane>
            <TabPane tab="매입 통계자료" key="6">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="매입 통계자료를 보여줍니다." />
                <ShippingStatistics />
            </TabPane>
          </Tabs>

          <Drawer className="mycustom" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                  placement='right'  width={700} >
            {drawerMode === 'view' ? DrawerView : DrawerAddEdit }
          </Drawer>

          <Modal width={700} visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="사진보기"
              style={{ width: '100%' }}
              src={previewImage}
            />
          </Modal>
        </>
    ) : (<>
            {() => {sessionStorage.clear(); 
              openErrorModal('인증 시간 만료!', '인증 시간이 만료되어 로그인 화면으로 이동합니다.'); 
              navigate('/'); 
            }}
        </>);
}

export default Shipping;