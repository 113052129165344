import { Typography } from 'antd';
import { EditFilled, DeleteFilled, FileImageOutlined, CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const thousand_comma = (data) => {
    let _data = '' + data;
    _data = _data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return(_data);
}

export const cars_columns = [
    {
        title: '번호',
        dataIndex: 'rowNumber',
        width: 60,
        align: 'center',
        key: 'rowNumber',
    },
    {
        title: '차량번호',
        dataIndex: 'carNumber',
        width: 100,
        align: 'center',
        key: 'carNumber',
    },
    {
        title: '차량명칭',
        dataIndex: 'carName',
        width: 220,
        align: 'left',
        key: 'carName',
    },
    {
        title: '운행구역',
        dataIndex: 'carArea',
        width: 120,
        align: 'center',
        key: 'carArea',
    },
    {
        title: '차량분류',   // 승용, 화물, 지게차 등
        dataIndex: 'carType',
        width: 180,
        align: 'center',
        key: 'carType',
    },
    {
        title: '연료종류',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
    {
        title: '등록일자',
        dataIndex: 'dateOfPurchase',
        width: 180,
        align: 'center',
        key: 'dateOfPurchase',
    },
    {
        title: '소유자',
        dataIndex: 'owner',
        width: 160,
        align: 'center',
        key: 'owner',
    },
    {
        title: '금월 현재주유량',
        dataIndex: 'totalVolume',
        width: 120,
        align: 'right',
        key: 'totalVolume',
        render : (text, record) => {
            return (
            <>
                {text !== null && (<div>{text} ℓ</div>) }
            </>
            )
        }
    },
    {
        title: '금월 주행거리',
        dataIndex: '',
        width: 120,
        align: 'center',
        key: '',
    },
    {
        title: '차량사진',
        dataIndex: 'carImgs',
        width: 120,
        align: 'center',
        key: 'carImgs',
        render: (_, record) => (
            record.shippingImg ? <><FileImageOutlined style={{color:'#40a9ff', fontSize: '18px'}} /></> : <><CloseOutlined style={{color:'#ff7875', fontSize: '18px'}} /></>
        )
    },
];

export const cars_refueling_columns = [
    {
        title: '번호',
        dataIndex: 'rowNumber',
        width: 80,
        align: 'center',
        key: 'rowNumber',
    },
    {
        title: '주유일자',
        dataIndex: 'refuelDate',
        width: 160,
        align: 'center',
        key: 'refuelDate',
    },
    {
        title: '주유자',
        dataIndex: 'oiler',
        width: 140,
        align: 'center',
        key: 'oiler',
    },
    {
        title: '주유소',
        dataIndex: 'gasStation',
        width: 160,
        align: 'center',
        key: 'gasStation',
    },
    {
        title: '주유종류',   
        dataIndex: 'gasType',
        width: 140,
        align: 'center',
        key: 'gasType',
    },
    {
        title: '주유량',   
        dataIndex: 'fuelVolume',
        width: 100,
        align: 'right',
        key: 'fuelVolume',
        render : (text, record) => {
            return (
            <>
                <div>{text} ℓ</div>
            </>
            )
        }
    },
    {
        title: '주유금액',
        dataIndex: 'fuelAmount',
        width: 120,
        align: 'right',
        key: 'fuelAmount',
    },
];

export const cars_repair_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 100,
        align: 'center',
        key: 'id',
    },
    {
        title: '수리일자',
        dataIndex: 'carName',
        width: 220,
        align: 'left',
        key: 'carName',
    },
    {
        title: '수리업체',
        dataIndex: 'carArea',
        width: 120,
        align: 'left',
        key: 'carArea',
    },
    {
        title: '수리내역',   // 경유, 무연휘발유
        dataIndex: 'carType',
        width: 180,
        align: 'center',
        key: 'carType',
    },
    {
        title: '수리금액',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
];

export const cars_insurance_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 100,
        align: 'center',
        key: 'id',
    },
    {
        title: '보험사',
        dataIndex: 'carName',
        width: 220,
        align: 'left',
        key: 'carName',
    },
    {
        title: '담당자',
        dataIndex: 'carArea',
        width: 120,
        align: 'left',
        key: 'carArea',
    },
    {
        title: '연락처',
        dataIndex: 'carArea',
        width: 120,
        align: 'left',
        key: 'carArea',
    },
    {
        title: '가입기간',   // 경유, 무연휘발유
        dataIndex: 'carType',
        width: 180,
        align: 'center',
        key: 'carType',
    },
    {
        title: '보험종류',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
    {
        title: '보험금액',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
    {
        title: '가입처리자',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
];

export const cars_periodicinspection_columns = [
    {
        title: '번호',
        dataIndex: 'id',
        width: 100,
        align: 'center',
        key: 'id',
    },
    {
        title: '검사일',
        dataIndex: 'carName',
        width: 220,
        align: 'left',
        key: 'carName',
    },
    {
        title: '유효기간',
        dataIndex: 'carArea',
        width: 120,
        align: 'left',
        key: 'carArea',
    },
    {
        title: '검사시행장소',   // 경유, 무연휘발유
        dataIndex: 'carType',
        width: 180,
        align: 'center',
        key: 'carType',
    },
    {
        title: '주행거리',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
    {
        title: '보험금액',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
    {
        title: '검사시행자',   // 승용, 화물, 지게차 등
        dataIndex: 'fuelType',
        width: 140,
        align: 'center',
        key: 'fulelType',
    },
];
