import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faStoreSlash, faShopLock } from '@fortawesome/free-solid-svg-icons';

import { Typography } from 'antd';

const { Text } = Typography;

export const salesclients_columns = [
    {
        title: '코드번호',
        dataIndex: 'code',
        width: 40,
        align: 'center',
        key: 'code',
    },
    {
        title: '거래처명',
        dataIndex: 'shopName',
        width: 100,
        align: 'left',
        key: 'shopName',
        sorter: (a, b) => (a.shopName !== null && b.shopName !== null) && a.shopName.localeCompare(b.shopName),
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 14) {
                    dtext = text.substr(0, 14) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '대표자',
        dataIndex: 'businessOwner',
        width: 40,
        align: 'center',
        key: 'businessOwner',
        sorter: (a, b) => (a.businessOwner !== null && b.businessOwner !== null) && a.businessOwner.localeCompare(b.businessOwner),
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 4) {
                    dtext = text.substr(0, 4) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        width: 60,
        align: 'center',
        key: 'businessNumber',
    },
    {
        title: '등록일',
        dataIndex: 'registrationDate',
        width: 50,
        align: 'center',
        key: 'registrationDate',
        sorter: (a, b) => (a.registrationDate !== null && b.registrationDate !== null) && a.registrationDate.localeCompare(b.registrationDate),
    },
    {
        title: '폐업일',
        dataIndex: 'closedDate',
        width: 50,
        align: 'center',
        key: 'closedDate',
        sorter: (a, b) => (a.closedDate !== null && b.closedDate !== null) && a.closedDate.localeCompare(b.closedDate),
    },
    {
        title: '계약일',
        dataIndex: 'contractDate',
        width: 50,
        align: 'center',
        key: 'contractDate',
        sorter: (a, b) => (a.contractDate !== null && b.contractDate !== null) && a.contractDate.localeCompare(b.contractDate),
    },
    {
        title: '분류',
        dataIndex: 'classification',
        width: 20,
        align: 'center',
        key: 'classification',
    },
    {
        title: '과세유형',
        dataIndex: 'taxClassification',
        width: 40,
        align: 'center',
        key: 'taxClassification',
    },
    {
        title: '전화1',
        dataIndex: 'tel1',
        width: 70,
        align: 'center',
        key: 'tel1',
    },
    // {
    //     title: '메모',
    //     dataIndex: 'note',
    //     width: 80,
    //     align: 'center',
    //     key: 'note',
    // },
    // {
    //     title: '이메일',
    //     dataIndex: 'emial',
    //     width: 80,
    //     align: 'center',
    //     key: 'email',
    // },
    {
        title: '면허일자',
        dataIndex: 'licenseDate',
        width: 60,
        align: 'center',
        key: 'licenseDate',
    },
    {
        title: '면허번호',
        dataIndex: 'licenseNumber',
        width: 60,
        align: 'center',
        key: 'licenseNumber',
    },
    {
        title: '거래처 주소',
        dataIndex: 'shopAddress',
        width: 160,
        align: 'left',
        key: 'shopAddress',
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 24) {
                    dtext = text.substr(0, 24) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
];


export const salesclients2_columns = [
    {
        title: '코드번호',
        dataIndex: 'code',
        width: 30,
        align: 'center',
        key: 'code',
    },
    {
        title: '거래처명',
        dataIndex: 'shopName',
        width: 140,
        align: 'left',
        key: 'shopName',
        sorter: (a, b) => (a.shopName !== null && b.shopName !== null) && a.shopName.localeCompare(b.shopName),
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 14) {
                    dtext = text.substr(0, 14) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '대표자',
        dataIndex: 'businessOwner',
        width: 40,
        align: 'center',
        key: 'businessOwner',
        sorter: (a, b) => (a.businessOwner !== null && b.businessOwner !== null) && a.businessOwner.localeCompare(b.businessOwner),
        render: (text) => {
            let dtext;
            if(text !== null && text !== '' && text !== 'undefined') {
                if(text.length > 4) {
                    dtext = text.substr(0, 4) + '...';
                }
                else {
                    dtext = text;
                }
            }

            return <>{dtext}</>
        }
    },
    {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        width: 60,
        align: 'center',
        key: 'businessNumber',
    },
    {
        title: '등록일',
        dataIndex: 'registrationDate',
        width: 50,
        align: 'center',
        key: 'registrationDate',
    },
    {
        title: '계약일',
        dataIndex: 'contractDate',
        width: 50,
        align: 'center',
        key: 'contractDate',
    },
    // {
    //     title: '메모',
    //     dataIndex: 'note',
    //     width: 80,
    //     align: 'center',
    //     key: 'note',
    // },
    // {
    //     title: '이메일',
    //     dataIndex: 'emial',
    //     width: 80,
    //     align: 'center',
    //     key: 'email',
    // },
    {
        title: '면허일자',
        dataIndex: 'licenseDate',
        width: 60,
        align: 'center',
        key: 'licenseDate',
    },
    {
        title: '면허번호',
        dataIndex: 'licenseNumber',
        width: 60,
        align: 'center',
        key: 'licenseNumber',
    },
    {
        title: '사업자상태조회',
        dataIndex: 'numberCheck',
        width: 80,
        align: 'center',
        key: 'numberCheck',
        sorter: (a, b) => (a.numberCheck !== null && b.numberCheck !== null) && a.numberCheck.localeCompare(b.numberCheck),
        render: (text) => {
            let dtext;
            if(text === '계속사업자') {
                dtext = <><FontAwesomeIcon icon={faStore} style={{color:'#40a9ff'}} /> {text}</>;
            } else if(text === '휴업자') {
                dtext = <><FontAwesomeIcon icon={faShopLock} style={{color:'#ff7875'}} /> <Text type="danger">{text}</Text></>;
            } else if(text === '폐업자') {
                dtext = <><FontAwesomeIcon icon={faStoreSlash} style={{color:'#ff7875'}} /> <Text type="danger">{text}</Text></>;
            }

            return <>{dtext}</>
        }
    },
];
