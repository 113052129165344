import React, { useEffect, useState } from 'react';
import { Button, Card, Icon, Image, Transition } from 'semantic-ui-react';

function MobileApp() {

  const [ visible, setVisible ] = useState(false);
  const [ macvalue, setMacValue ] = useState('');

  const toggleVisible = () => setVisible(prevState => !prevState);

  useEffect(() => {
    console.log('toggle', visible)
  }, [visible])

  return (
    <>
        <Transition visible={visible} animation='horizontal flip' duration={500}>
          <Card centered visible={true}>
          <Image src='/images/avatar/large/matthew.png' wrapped ui={false} />
          <Card.Content>
            <Card.Header>매튜</Card.Header>
            <Card.Meta>
              <span className='date'>2015년 가입</span>
            </Card.Meta>
            <Card.Description>
              Matthew is a musician living in Nashville.
              {/* {macvalue} */}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Icon name='user' />
              친구 22명
            </a>
          </Card.Content>
          </Card>
        </Transition>
        <Button onClick={toggleVisible}>카드 회전</Button>
    </>
  );
}

export default MobileApp;